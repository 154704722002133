<template>
  <b-overlay
    :show="ShowOverlay"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div>
      <div class="card p-1">
        <b-form @submit.prevent="onSubmit">
          <div class="row">
            <div class="col-md-12">
              <b-form-group>
                <label for="login-name">Provider :</label>
                <b-form-checkbox-group
                  id="checkbox-group-2"
                  v-model="Provider"
                  :options="ProviderOptions"
                  name="flavour-1"
                  style="margin-top: -12px;"
                  class="demo-inline-spacing"
                  @change="ChangeProvider()"
                />
              </b-form-group>
            </div>

            <div class="col-md-4">
              <b-form-group>
                <label for="s_date">Start Date :</label>
                <b-form-datepicker
                  id="s_date"
                  v-model="s_date"
                  class="mb-1"
                />
              </b-form-group>
            </div>

            <div class="col-md-4">
              <b-form-group>
                <label for="t_date">To Date :</label>
                <b-form-datepicker
                  id="t_date"
                  v-model="t_date"
                  class="mb-1"
                />
              </b-form-group>
            </div>

            <!-- <div class="col-md-4">
              <b-form-group>
                <label for="login-name">Currency :</label>
                <v-select
                  v-model="currency"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="curOption"
                />
              </b-form-group>
            </div> -->
            <div
              v-if="Provider.indexOf('All') > -1 || Provider.indexOf('Askmebet') > -1"
              class="col-md-4"
            >
              <b-form-group>
                <label for="login-name">Askmebet Product :</label>
                <v-select
                  v-model="product"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="productName"
                  :options="proOption"
                  :reduce="option => option.productId"
                />
              </b-form-group>
            </div>

            <div
              v-if="Provider.indexOf('All') > -1 || Provider.indexOf('Lotto') > -1"
              class="col-md-4"
            >
              <b-form-group>
                <label for="login-name">Lotto Product :</label>
                <v-select
                  v-model="LottoType"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="LottoHeadName"
                  :options="LottoOption"
                  multiple
                  :reduce="option => option.LottoTypeHead"
                />
              </b-form-group>
            </div>

            <div class="col-md-4">
              <b-form-group>
                <label for="login-name">Login name :</label>
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="hi-first-name"
                    v-model="login_name"
                    placeholder="Enter Login Name"
                  />
                </b-input-group>
              </b-form-group>
            </div>

            <div class="col-md-4">
              <b-form-group>
                <label for="login-name">Report By :</label>
                <b-form-checkbox-group
                  id="checkbox-group-1"
                  v-model="report_by"
                  :options="reportOptions"
                  name="flavour-1"
                  style="margin-top: -12px;"
                  class="demo-inline-spacing"
                />
              </b-form-group>
            </div>

            <div class="col-md-2">
              <button class="btn btn-gradient-primary">
                <i class="fa-solid fa-magnifying-glass" /> Search
              </button>
            </div>
          </div>
        </b-form>
      </div>

      <div class="card">

        <!-- <div class="row mx-1 mt-2">
          <div class="col-md-3">
            <b-form-group>
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="hi-first-name"
                  v-model="login_name"
                  placeholder="Enter Login Name"
                  @input="FilterName(login_name)"
                />
              </b-input-group>
            </b-form-group>
          </div>
        </div> -->

        <div class="d-flex justify-content-between">
          <div class="multi-button">
            <button
              class="btn-gg"
              :class="{ activated: activeBtn === 'Provious' }"
              @click="SelectDate('Provious')"
            >
              Provious
            </button>
            <button
              class="btn-gg"
              :class="{ activated: activeBtn === 'Today' }"
              @click="SelectDate('Today')"
            >
              Today
            </button>
            <button
              class="btn-gg"
              :class="{ activated: activeBtn === 'Yesterday' }"
              @click="SelectDate('Yesterday')"
            >
              Yesterday
            </button>
            <button
              class="btn-gg"
              :class="{ activated: activeBtn === 'ThisWeek' }"
              @click="SelectDate('ThisWeek')"
            >
              This Week
            </button>
            <button
              class="btn-gg"
              :class="{ activated: activeBtn === 'LastWeek' }"
              @click="SelectDate('LastWeek')"
            >
              Last Week
            </button>
            <button
              class="btn-gg"
              :class="{ activated: activeBtn === 'ThisMonth' }"
              @click="SelectDate('ThisMonth')"
            >
              This Month
            </button>
            <button
              class="btn-gg"
              :class="{ activated: activeBtn === 'LastMonth' }"
              @click="SelectDate('LastMonth')"
            >
              Last Month
            </button>
            <button
              class="btn-gg"
              :class="{ activated: activeBtn === 'Next' }"
              @click="SelectDate('Next')"
            >
              Next
            </button>
          </div>

          <div
            class="currency yellow-bar"
            style="margin-top: 16px;"
          >
            <i class="fa-solid fa-sack-dollar" /> <span>Currency : THB</span>
          </div>
        </div>

        <!-- Table here -->
        <div class="table-responsive">
          <table class="table table-bordered table-hover">
            <thead>
              <tr>
                <th
                  v-if="showProduct"
                  colspan="10"
                >

                  <div
                    v-for="(Items, Index) in Station"
                    :key="Index"
                  >

                    <span
                      v-if="Index < Station.length - 1 || Index === 0"
                      @click="SelectMember(Items, 'back')"
                    >
                      {{ Items }}
                    </span>
                    <span v-else>/ {{ Items }} </span>

                  </div>
                </th>

                <th
                  v-else
                  colspan="9"
                >
                  <span
                    v-for="(Items, Index) in Station"
                    :key="Index"
                  >

                    <a
                      v-if="Index < Station.length - 1 || Index === 0"
                      @click="SelectMember(Items, 'back')"
                    >
                      <i class="fa fa-user" /> {{ Items }}
                    </a>
                    <a v-else>/ {{ Items }} </a>

                  </span>
                </th>
                <th
                  colspan="3"
                  style="text-align: center"
                >
                  Member
                </th>
                <th
                  colspan="3"
                  style="text-align: center"
                >
                  Company
                </th>
                <th
                  colspan="3"
                  style="text-align: center"
                >
                  Provider
                </th>
              </tr>
              <tr>
                <th
                  class="col-text-padding"
                  style="text-align: center; width: 1%"
                >
                  No.
                </th>
                <th
                  class="col-text-padding"
                  style="text-align: center; width: 1%"
                >
                  Select
                  <b-button
                    v-if="SelectAg.length > 0"
                    variant="relief-success"
                    size="sm"
                    class="mt-1"
                    @click="showModalSelect()"
                  >
                    confirm
                  </b-button>
                </th>
                <th
                  class="col-text-padding"
                  style="text-align: center; width: 10%"
                >
                  Login Name
                </th>
                <th
                  class="col-text-padding"
                  style="text-align: center; width: 5%"
                >
                  Position
                </th>
                <th
                  v-if="showProduct"
                  class="col-text-padding"
                  style="text-align: center; width: 5%"
                >
                  Product
                </th>
                <th
                  class="col-text-padding"
                  style="text-align: right; width: 5%"
                >
                  No.Ticket
                </th>
                <th style="text-align: right; width: 5%">
                  Valid turn
                </th>
                <th
                  class="col-text-padding"
                  style="text-align: right; width: 5%"
                >
                  Member W/L <br>(100%)
                </th>
                <th
                  class="col-text-padding"
                  style="text-align: right; width: 8%"
                >
                  Tips
                </th>
                <th
                  class="col-text-padding"
                  style="text-align: right; width: 8%"
                >
                  Provider<br>Event
                </th>
                <th
                  class="col-text-padding"
                  style="text-align: right; width: 5%"
                >
                  W/L
                </th>
                <th style="text-align: right; width: 5%">
                  Comm
                </th>
                <th style="text-align: right; width: 5%">
                  Total
                </th>
                <th
                  class="col-text-padding"
                  style="text-align: right; width: 5%"
                >
                  W/L
                </th>
                <th style="text-align: right; width: 5%">
                  Comm
                </th>
                <th
                  class="col-text-padding"
                  style="text-align: right; width: 5%"
                >
                  Total
                </th>
                <th
                  class="col-text-padding"
                  style="text-align: right; width: 5%"
                >
                  W/L
                </th>
                <th style="text-align: right; width: 5%">
                  Comm
                </th>
                <th
                  class="col-text-padding"
                  style="text-align: right; width: 5%"
                >
                  Total
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(Items, Index) in GrandRow.slice((currentPage - 1) * perPage, currentPage * perPage)"
                :key="Index"
              >
                <td>{{ perPage * (currentPage - 1) + (Index + 1) }}</td>
                <td>
                  <b-form-checkbox
                    v-model="SelectAg"
                    :value="Items"
                  />
                </td>
                <td
                  v-if="(userData.role == 'master' || userData.role == 'company') && Station.length >= 2"
                  style="text-align: left; color:blue"
                  @click="AllDetailMember(Items)"
                >

                  <p>
                    {{ Items.Username }}
                  </p>
                </td>
                <!-- <td
                  v-if="(userData.role == 'master' || userData.role == 'company') && Station.length >= 2 && Items.amb_id"
                  style="text-align: left; color:blue"
                  @click="DetailMember(Items)"
                >
                  <p>
                    {{ Items.Username }}
                  </p>
                </td>
                <td
                  v-else-if="(userData.role == 'master' || userData.role == 'company') && Station.length >= 2"
                  style="text-align: left; color:black"
                >
                  <p>
                    {{ Items.Username }}
                  </p>
                </td> -->
                <td
                  v-else-if="userData.role == 'agent'"
                  style="text-align: left; color:blue"
                  @click="AllDetailMember(Items)"
                >
                  <p>
                    {{ Items.Username }}
                  </p>
                </td>
                <!-- <td
                  v-else-if="userData.role == 'agent' && Items.amb_id"
                  style="text-align: left; color:blue"
                  @click="DetailMember(Items)"
                >
                  <p>
                    {{ Items.Username }}
                  </p>
                </td>
                <td
                  v-else-if="userData.role == 'agent'"
                  style="text-align: left; color:black"
                >
                  <p>
                    {{ Items.Username }}
                  </p>
                </td> -->
                <td
                  v-else
                  style="text-align: left; color:blue"
                >
                  <p @click="SelectMember(Items.Username, 'next')">
                    {{ Items.Username }}
                  </p>
                </td>

                <td style="text-align: center">
                  {{ Items.Position }}
                </td>
                <td
                  v-if="showProduct"
                  style="text-align: center"
                >
                  <button
                    class="btn btn-gradient-primary"
                    @click="showModal(Items.Product)"
                  >
                    {{ Items.Product.length }}
                  </button>

                </td>
                <td style="text-align: right">
                  {{ Commas(Items.BetCount) }}
                </td>
                <td style="text-align: right; color: black">
                  {{ Commas(Items.ValidStake) }}
                </td>
                <td :style="`text-align: right; color: ${Items.WinLose < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                  {{ Commas(Items.WinLose) }}
                </td>
                <td style="text-align: right; color: black">
                  {{ Commas(Items.Tips) }}
                </td>
                <td style="text-align: right; color: black">
                  {{ Commas(Items.ProviderEvent) }}
                </td>
                <td :style="`text-align: right; color: ${Items.member_winlose < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                  {{ Commas(Items.member_winlose) }}
                </td>
                <td :style="`text-align: right; color: ${Items.member_com < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                  {{ Commas(Items.member_com) }}
                </td>
                <td :style="`text-align: right; color: ${Items.member_total < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                  {{ Commas(Items.member_total) }}
                </td>
                <td
                  :style="`
              background-color: rgb(248, 245, 213);
              text-align: right;
              color: ${Items.company_winlose < 0 ? 'rgb(212, 34, 34)' : 'black'};
            `"
                >
                  {{ Commas(Items.company_winlose) }}
                </td>
                <td
                  :style="`
              background-color: rgb(248, 245, 213);
              text-align: right;
              color: ${Items.company_com < 0 ? 'rgb(212, 34, 34)' : 'black'};
            `"
                >
                  {{ Commas(Items.company_com) }}
                </td>
                <td
                  :style="`
              background-color: rgb(248, 245, 213);
              text-align: right;
              color: ${Items.company_total < 0 ? 'rgb(212, 34, 34)' : 'black'};
            `"
                >
                  {{ Commas(Items.company_total) }}
                </td>
                <td :style="`text-align: right; color: ${Items.provider_winlose < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                  {{ Commas(Items.provider_winlose) }}
                </td>
                <td :style="`text-align: right; color: ${Items.provider_com < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                  {{ Commas(Items.provider_com) }}
                </td>
                <td :style="`text-align: right; color: ${Items.provider_total < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                  {{ Commas(Items.provider_total) }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr class="footer">
                <td style="text-align: right">
                  Total
                </td>
                <td
                  v-if="showProduct"
                  colspan="4"
                />
                <td
                  v-else
                  colspan="3"
                />
                <td style="text-align: right">
                  {{ Commas(GrandTotal.BetCount) }}
                </td>
                <td style="text-align: right;">
                  {{ Commas(GrandTotal.ValidStake) }}
                </td>
                <td :style="`text-align: right; color: ${GrandTotal.WinLose < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                  {{ Commas(GrandTotal.WinLose) }}
                </td>
                <td style="text-align: right;">
                  {{ Commas(GrandTotal.Tips) }}
                </td>
                <td style="text-align: right;">
                  {{ Commas(GrandTotal.ProviderEvent) }}
                </td>
                <td :style="`text-align: right; color: ${GrandTotal.member_winlose < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                  {{ Commas(GrandTotal.member_winlose) }}
                </td>
                <td :style="`text-align: right; color: ${GrandTotal.member_com < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                  {{ Commas(GrandTotal.member_com) }}
                </td>
                <td :style="`text-align: right; color: ${GrandTotal.member_total < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                  {{ Commas(GrandTotal.member_total) }}
                </td>
                <td :style="`text-align: right; color: ${GrandTotal.company_winlose < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                  {{ Commas(GrandTotal.company_winlose) }}
                </td>
                <td :style="`text-align: right; color: ${GrandTotal.company_com < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                  {{ Commas(GrandTotal.company_com) }}
                </td>
                <td :style="`text-align: right; color: ${GrandTotal.company_total < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                  {{ Commas(GrandTotal.company_total) }}
                </td>
                <td
                  :style="`text-align: right; color: ${GrandTotal.provider_winlose < 0 ? 'rgb(212, 34, 34)' : 'black'}`"
                >
                  {{ Commas(GrandTotal.provider_winlose) }}
                </td>
                <td :style="`text-align: right; color: ${GrandTotal.provider_com < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                  {{ Commas(GrandTotal.provider_com) }}
                </td>
                <td :style="`text-align: right; color: ${GrandTotal.provider_total < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                  {{ Commas(GrandTotal.provider_total) }}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>

        <!-- Pagination here -->
        <div class="demo-spacing-0 mx-1 my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="GrandRow.length"
            :per-page="perPage"
            align="right"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>
      <!-- modal this here where -->
      <b-modal
        ref="my-modal"
        hide-footer
        size="xl"
        title="Show Product Details"
      >
        <div class="table-responsive">
          <table class="table table-bordered table-hover">
            <thead>
              <tr>
                <th colspan="7" />
                <th
                  colspan="3"
                  style="text-align: center"
                >
                  Member
                </th>
                <th
                  colspan="3"
                  style="text-align: center"
                >
                  Company
                </th>
                <th
                  colspan="3"
                  style="text-align: center"
                >
                  Provider
                </th>
              </tr>
              <tr>
                <th
                  class="col-text-padding"
                  style="text-align: center; width: 1%"
                >
                  No.
                </th>
                <th
                  class="col-text-padding"
                  style="text-align: center; width: 10%"
                >
                  Product Name
                </th>
                <th
                  class="col-text-padding"
                  style="text-align: right; width: 5%"
                >
                  Provider Event
                </th>
                <th
                  class="col-text-padding"
                  style="text-align: center; width: 5%"
                >
                  BetCount
                </th>
                <th style="text-align: right; width: 5%">
                  Tips
                </th>
                <th
                  class="col-text-padding"
                  style="text-align: right; width: 5%"
                >
                  Valid Stake
                </th>
                <th
                  class="col-text-padding"
                  style="text-align: right; width: 8%"
                >
                  WinLose
                </th>

                <th
                  class="col-text-padding"
                  style="text-align: right; width: 5%"
                >
                  W/L
                </th>
                <th style="text-align: right; width: 5%">
                  Comm
                </th>
                <th style="text-align: right; width: 5%">
                  Total
                </th>
                <th
                  class="col-text-padding"
                  style="text-align: right; width: 5%"
                >
                  W/L
                </th>
                <th style="text-align: right; width: 5%">
                  Comm
                </th>
                <th
                  class="col-text-padding"
                  style="text-align: right; width: 5%"
                >
                  Total
                </th>
                <th
                  class="col-text-padding"
                  style="text-align: right; width: 5%"
                >
                  W/L
                </th>
                <th style="text-align: right; width: 5%">
                  Comm
                </th>
                <th
                  class="col-text-padding"
                  style="text-align: right; width: 5%"
                >
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(Items, Index) in detailProduct"
                :key="Index"
              >
                <td>{{ Index + 1 }}</td>

                <td style="text-align: center">
                  {{ Items.ProductName }}
                </td>
                <td style="text-align: right">
                  {{ Commas(Items.ProviderEvent) }}
                </td>
                <td style="text-align: right; color: black">
                  {{ Commas(Items.BetCount) }}
                </td>
                <td :style="`text-align: right; color: ${Items.Tips < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                  {{ Commas(Items.Tips) }}
                </td>
                <td style="text-align: right; color: black">
                  {{ Commas(Items.ValidStake) }}
                </td>
                <td :style="`text-align: right; color: ${Items.WinLose < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                  {{ Commas(Items.WinLose) }}
                </td>

                <td :style="`text-align: right; color: ${Items.member_winlose < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                  {{ Commas(Items.member_winlose) }}
                </td>
                <td style="text-align: right; color: black">
                  {{ Commas(Items.member_com) }}
                </td>
                <td :style="`text-align: right; color: ${Items.member_total < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                  {{ Commas(Items.member_total) }}
                </td>
                <td
                  :style="`background-color: rgb(248, 245, 213); text-align: right; color: ${Items.company_winlose < 0 ? 'rgb(212, 34, 34)' : 'black'}`"
                >
                  {{ Commas(Items.company_winlose) }}
                </td>
                <td style="background-color: rgb(248, 245, 213);text-align: right;color: black;">
                  {{ Commas(Items.company_com) }}
                </td>
                <td
                  :style="`background-color: rgb(248, 245, 213); text-align: right; color: ${Items.company_total < 0 ? 'rgb(212, 34, 34)' : 'black'}`"
                >
                  {{ Commas(Items.company_total) }}
                </td>
                <td :style="`text-align: right; color: ${Items.provider_winlose < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                  {{ Commas(Items.provider_winlose) }}
                </td>
                <td style="text-align: right; color: black">
                  {{ Commas(Items.provider_com) }}
                </td>
                <td :style="`text-align: right; color: ${Items.provider_total < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                  {{ Commas(Items.provider_total) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-modal>
      <b-modal
        ref="detail-modal"
        hide-footer
        size="xl"
        :title="`Bet detail: ${DetailMemberData.Row.length > 0 ? DetailMemberData.Row[0].Username : ''}`"
      >
        <b-overlay
          :show="OverMem"
          variant="transparent"
          opacity="0.99"
          blur="5px"
          rounded="sm"
        >
          <template #overlay>
            <div class="text-center">
              <b-icon-controller
                font-scale="3"
                animation="cylon"
              />
              <p id="cancel-label">
                กรุณารอสักครู่...
              </p>
            </div>
          </template>
          <div class="table-responsive">
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th colspan="9" />
                  <th
                    colspan="3"
                    style="text-align: center"
                  >
                    Member
                  </th>
                  <th
                    colspan="3"
                    style="text-align: center"
                  >
                    Company
                  </th>
                  <th
                    colspan="3"
                    style="text-align: center"
                  >
                    Provider
                  </th>
                </tr>
                <tr>
                  <th
                    class="col-text-padding"
                    style="text-align: center; width: 1%"
                  >
                    No.
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: center; width: 10%"
                  >
                    Product Name
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: right; width: 5%"
                  >
                    Start Time
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: right; width: 5%"
                  >
                    End Time
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: center; width: 5%"
                  >
                    BetCount
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: right; width: 5%"
                  >
                    Provider Event
                  </th>
                  <th style="text-align: right; width: 5%">
                    Tips
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: right; width: 5%"
                  >
                    Valid turn
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: right; width: 8%"
                  >
                    WinLose
                  </th>

                  <th
                    class="col-text-padding"
                    style="text-align: right; width: 5%"
                  >
                    W/L
                  </th>
                  <th style="text-align: right; width: 5%">
                    Comm
                  </th>
                  <th style="text-align: right; width: 5%">
                    Total
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: right; width: 5%"
                  >
                    W/L
                  </th>
                  <th style="text-align: right; width: 5%">
                    Comm
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: right; width: 5%"
                  >
                    Total
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: right; width: 5%"
                  >
                    W/L
                  </th>
                  <th style="text-align: right; width: 5%">
                    Comm
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: right; width: 5%"
                  >
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <!-- {{ DetailMemberData.Row }} -->
                <tr
                  v-for="(Items, Index) in DetailMemberData.Row"
                  :key="Index"
                >
                  <td>{{ Index + 1 }}</td>

                  <td
                    style="text-align: center; color:blue"
                    @click="DetailEvent(Items)"
                  >
                    {{ Items.ProductName }}
                  </td>
                  <td style="text-align: right">
                    {{ FormatDate(Items.StartTime) }}
                  </td>
                  <td style="text-align: right">
                    {{ FormatDate(Items.EndTime) }}
                  </td>
                  <td style="text-align: right; color: black">
                    {{ Commas(Items.BetCount) }}
                  </td>
                  <td style="text-align: right">
                    {{ Commas(Items.ProviderEvent) }}
                  </td>
                  <td :style="`text-align: right; color: ${Items.Tips < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                    {{ Commas(Items.Tips) }}
                  </td>
                  <td style="text-align: right; color: black">
                    {{ Commas(Items.ValidStake) }}
                  </td>
                  <td :style="`text-align: right; color: ${Items.WinLose < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                    {{ Commas(Items.WinLose) }}
                  </td>

                  <td :style="`text-align: right; color: ${Items.member_winlose < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                    {{ Commas(Items.member_winlose) }}
                  </td>
                  <td style="text-align: right; color: black">
                    {{ Commas(Items.member_com) }}
                  </td>
                  <td :style="`text-align: right; color: ${Items.member_total < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                    {{ Commas(Items.member_total) }}
                  </td>
                  <td
                    :style="`background-color: rgb(248, 245, 213); text-align: right; color: ${Items.company_winlose < 0 ? 'rgb(212, 34, 34)' : 'black'}`"
                  >
                    {{ Commas(Items.company_winlose) }}
                  </td>
                  <td style="background-color: rgb(248, 245, 213);text-align: right;color: black;">
                    {{ Commas(Items.company_com) }}
                  </td>
                  <td
                    :style="`background-color: rgb(248, 245, 213); text-align: right; color: ${Items.company_total < 0 ? 'rgb(212, 34, 34)' : 'black'}`"
                  >
                    {{ Commas(Items.company_total) }}
                  </td>
                  <td :style="`text-align: right; color: ${Items.provider_winlose < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                    {{ Commas(Items.provider_winlose) }}
                  </td>
                  <td style="text-align: right; color: black">
                    {{ Commas(Items.provider_com) }}
                  </td>
                  <td :style="`text-align: right; color: ${Items.provider_total < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                    {{ Commas(Items.provider_total) }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr class="footer">
                  <td style="text-align: right">
                    Total
                  </td>
                  <td colspan="3" />
                  <td style="text-align: right">
                    {{ Commas(DetailMemberData.Total.BetCount) }}
                  </td>
                  <td style="text-align: right;">
                    {{ Commas(DetailMemberData.Total.ProviderEvent) }}
                  </td>
                  <td style="text-align: right;">
                    {{ Commas(DetailMemberData.Total.Tips) }}
                  </td>
                  <td style="text-align: right;">
                    {{ Commas(DetailMemberData.Total.ValidStake) }}
                  </td>
                  <td
                    :style="`text-align: right; color: ${DetailMemberData.Total.WinLose < 0 ? 'rgb(212, 34, 34)' : 'black'}`"
                  >
                    {{ Commas(DetailMemberData.Total.WinLose) }}
                  </td>
                  <td
                    :style="`text-align: right; color: ${DetailMemberData.Total.member_winlose < 0 ? 'rgb(212, 34, 34)' : 'black'}`"
                  >
                    {{ Commas(DetailMemberData.Total.member_winlose) }}
                  </td>
                  <td
                    :style="`text-align: right; color: ${DetailMemberData.Total.member_com < 0 ? 'rgb(212, 34, 34)' : 'black'}`"
                  >
                    {{ Commas(DetailMemberData.Total.member_com) }}
                  </td>
                  <td
                    :style="`text-align: right; color: ${DetailMemberData.Total.member_total < 0 ? 'rgb(212, 34, 34)' : 'black'}`"
                  >
                    {{ Commas(DetailMemberData.Total.member_total) }}
                  </td>
                  <td
                    :style="`text-align: right; color: ${DetailMemberData.Total.company_winlose < 0 ? 'rgb(212, 34, 34)' : 'black'}`"
                  >
                    {{ Commas(DetailMemberData.Total.company_winlose) }}
                  </td>
                  <td
                    :style="`text-align: right; color: ${DetailMemberData.Total.company_com < 0 ? 'rgb(212, 34, 34)' : 'black'}`"
                  >
                    {{ Commas(DetailMemberData.Total.company_com) }}
                  </td>
                  <td
                    :style="`text-align: right; color: ${DetailMemberData.Total.company_total < 0 ? 'rgb(212, 34, 34)' : 'black'}`"
                  >
                    {{ Commas(DetailMemberData.Total.company_total) }}
                  </td>
                  <td
                    :style="`text-align: right; color: ${DetailMemberData.Total.provider_winlose < 0 ? 'rgb(212, 34, 34)' : 'black'}`"
                  >
                    {{ Commas(DetailMemberData.Total.provider_winlose) }}
                  </td>
                  <td
                    :style="`text-align: right; color: ${DetailMemberData.Total.provider_com < 0 ? 'rgb(212, 34, 34)' : 'black'}`"
                  >
                    {{ Commas(DetailMemberData.Total.provider_com) }}
                  </td>
                  <td
                    :style="`text-align: right; color: ${DetailMemberData.Total.provider_total < 0 ? 'rgb(212, 34, 34)' : 'black'}`"
                  >
                    {{ Commas(DetailMemberData.Total.provider_total) }}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </b-overlay>
      </b-modal>
      <!-- Bet Event -->
      <b-modal
        ref="event-amb"
        hide-footer
        size="xl"
        :title="`ASKMEBET: ${DetailEventData.Row.length > 0 ? DetailEventData.Row[0].Username : ''}`"
      >
        <b-overlay
          :show="OverEvent"
          variant="transparent"
          opacity="0.99"
          blur="5px"
          rounded="sm"
        >
          <template #overlay>
            <div class="text-center">
              <b-icon-controller
                font-scale="3"
                animation="cylon"
              />
              <p id="cancel-label">
                กรุณารอสักครู่...
              </p>
            </div>
          </template>
          <div class="table-responsive">
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th colspan="9" />
                  <th
                    colspan="1"
                    style="text-align: center"
                  >
                    Member
                  </th>
                  <th
                    colspan="1"
                    style="text-align: center"
                  >
                    Company
                  </th>
                  <th
                    colspan="1"
                    style="text-align: center"
                  >
                    Provider
                  </th>
                  <th colspan="1" />
                </tr>
                <tr>
                  <th
                    class="col-text-padding"
                    style="text-align: center; width: 1%"
                  >
                    No.
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: center; width: 16%"
                  >
                    Information
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: right; width: 16%"
                  >
                    Details
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: right; width: 16%"
                  >
                    Event
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: center; width: 6%"
                  >
                    Bet Type
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: right; width: 6%"
                  >
                    Game Detail
                  </th>
                  <th style="text-align: right; width: 5%">
                    Tips
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: right; width: 5%"
                  >
                    Provider Event
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: right; width: 5%"
                  >
                    Amount
                  </th>

                  <th
                    class="col-text-padding"
                    style="text-align: right; width: 6%"
                  >
                    Win Lose Comm
                  </th>
                  <th style="text-align: right; width: 6%">
                    Win Lose Comm
                  </th>
                  <th style="text-align: right; width: 6%">
                    Win Lose Comm
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: right; width: 6%"
                  >
                    Result
                  </th>
                </tr>
              </thead>
              <tbody>
                <!-- {{ DetailMemberData.Row }} -->
                <tr
                  v-for="(Items, Index) in DetailEventData.Row"
                  :key="Index"
                >
                  <td>{{ Index + 1 }}</td>

                  <td style="text-align: center">
                    <p>ID: {{ Items.ID }}</p>
                    <p>Settle: {{ FormatDate(Items.SettleTime) }}</p>
                  </td>
                  <td style="text-align: right">
                    <p>Provider: {{ Items.ProductName }}</p>
                    <p>Category: {{ Items.Category }}</p>
                  </td>
                  <td style="text-align: right">
                    <p>GameName: {{ Items.GameName === 'UNKNOWN' ? '-' : Items.GameName }}</p>
                    <p>Bet: {{ Items.Bet === 'UNKNOWN' ? '-' : Items.Bet }}</p>
                  </td>
                  <td style="text-align: right; color: black">
                    {{ Items.BetType === 'UNKNOWN' ? '-' : Items.BetType }}
                  </td>
                  <td style="text-align: right">
                    <b-button
                      variant="primary"
                      size="sm"
                      @click="DetailImg(Items)"
                    >
                      <i class="fas fa-search" />
                      Detail
                    </b-button>
                  </td>
                  <td :style="`text-align: right; color: ${Items.Tips < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                    {{ Commas(Items.Tips) }}
                  </td>
                  <td style="text-align: right; color: black">
                    {{ Commas(Items.ProviderEvent) }}
                  </td>
                  <td style="text-align: right; color: black">
                    {{ Commas(Items.Amount) }}
                  </td>
                  <td :style="`text-align: right; color: ${Items.member_winlose < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                    {{ Commas(Items.member_winlose) }}
                  </td>

                  <td :style="`text-align: right; color: ${Items.company_winlose < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                    {{ Commas(Items.company_winlose) }}
                  </td>
                  <td :style="`text-align: right; color: ${Items.provider_winlose < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                    {{ Commas(Items.provider_winlose) }}
                  </td>
                  <td
                    :style="`text-align: right; color: ${Items.Result == 'WIN' ? 'rgb(65, 163, 23)' : Items.Result == 'LOSE' ? 'rgb(158, 16, 47)' : 'rgb(153, 92, 31)'}`"
                  >
                    {{ Items.Result }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr class="footer">
                  <td style="text-align: right">
                    Total
                  </td>
                  <td colspan="5" />
                  <td style="text-align: right;">
                    {{ Commas(DetailEventData.Total.Tips) }}
                  </td>
                  <td style="text-align: right;">
                    {{ Commas(DetailEventData.Total.ProviderEvent) }}
                  </td>
                  <td style="text-align: right;">
                    {{ Commas(DetailEventData.Total.Amount) }}
                  </td>
                  <td
                    :style="`text-align: right; color: ${DetailMemberData.Total.member_winlose < 0 ? 'rgb(212, 34, 34)' : 'black'}`"
                  >
                    {{ Commas(DetailEventData.Total.member_winlose) }}
                  </td>
                  <td
                    :style="`text-align: right; color: ${DetailMemberData.Total.company_winlose < 0 ? 'rgb(212, 34, 34)' : 'black'}`"
                  >
                    {{ Commas(DetailEventData.Total.company_winlose) }}
                  </td>
                  <td
                    :style="`text-align: right; color: ${DetailMemberData.Total.provider_winlose < 0 ? 'rgb(212, 34, 34)' : 'black'}`"
                  >
                    {{ Commas(DetailEventData.Total.provider_winlose) }}
                  </td>
                  <td colspan="1" />
                </tr>
              </tfoot>
            </table>
          </div>
        </b-overlay>
      </b-modal>
      <b-modal
        ref="event-lotto"
        hide-footer
        size="xl"
        :title="`Lotto: ${DetailEventLotto.length > 0 ? DetailEventLotto[0].username : ''}`"
      >
        <b-overlay
          :show="OverEvent"
          variant="transparent"
          opacity="0.99"
          blur="5px"
          rounded="sm"
        >
          <template #overlay>
            <div class="text-center">
              <b-icon-controller
                font-scale="3"
                animation="cylon"
              />
              <p id="cancel-label">
                กรุณารอสักครู่...
              </p>
            </div>
          </template>
          <div class="table-responsive">
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th
                    class="col-text-padding"
                    style="text-align: center; width: 1%"
                  >
                    No.
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: center; width: 16%"
                  >
                    Information
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: right; width: 16%"
                  >
                    Timestamp
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: right; width: 10%"
                  >
                    Lotto name
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: center; width: 6%"
                  >
                    Type
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: right; width: 6%"
                  >
                    Bet number
                  </th>
                  <th style="text-align: right; width: 5%">
                    Amount
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: right; width: 5%"
                  >
                    Result
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: right; width: 5%"
                  >
                    Win rate
                  </th>

                  <th
                    class="col-text-padding"
                    style="text-align: right; width: 6%"
                  >
                    Reward
                  </th>
                  <th style="text-align: right; width: 12%">
                    Log balance
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: right; width: 6%"
                  >
                    Result
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(Items, Index) in DetailEventLotto"
                  :key="Index"
                >
                  <td>{{ Index + 1 }}</td>

                  <td style="text-align: center">
                    <p>ID: {{ Items._id }}</p>
                    <p>List ID: {{ Items.list_id }}</p>
                    <p>Round ID: {{ Items.round_id }}</p>
                  </td>
                  <td style="text-align: right">
                    <p>Bet: {{ FormatDate(Items.created_at) }}</p>
                    <p>Settle: {{ FormatDate(Items.updated_at) }}</p>
                  </td>
                  <td style="text-align: right">
                    <p>{{ Items.LottoHeadName }}</p>
                  </td>
                  <td style="text-align: right; color: black">
                    <p>{{ Items.name_type }}</p>
                  </td>
                  <td style="text-align: right; color: blue">
                    <p>{{ Items.bet }}</p>
                  </td>
                  <td style="text-align: right; color: green">
                    <p>{{ Commas(Items.amount) }}</p>
                  </td>
                  <td style="text-align: right; color: blue">
                    <p>{{ Items.result_bet }}</p>
                  </td>
                  <td style="text-align: right; color: black">
                    <p>{{ Commas(Items.win_rate) }}</p>
                  </td>
                  <td style="text-align: right; color: black">
                    <p>{{ Commas(Items.reward) }}</p>
                  </td>
                  <td style="text-align: right">
                    <p>Start Balance: {{ Commas(Items.startbalance) }}</p>
                    <p>End Balance: {{ Commas(Items.endbalance) }}</p>
                  </td>
                  <td
                    :style="`text-align: right; color: ${Items.status_result == 'win' ? 'rgb(65, 163, 23)' : Items.status_result == 'lose' ? 'rgb(158, 16, 47)' : 'rgb(153, 92, 31)'}`"
                  >
                    {{ Items.status_result }}
                  </td>
                </tr>
              </tbody>
              <!-- <tfoot>
                <tr class="footer">
                  <td style="text-align: right">
                    Total
                  </td>
                  <td
                    colspan="5"
                  />
                  <td style="text-align: right;">
                    {{ Commas(DetailEventData.Total.Tips) }}
                  </td>
                  <td style="text-align: right;">
                    {{ Commas(DetailEventData.Total.ProviderEvent) }}
                  </td>
                  <td style="text-align: right;">
                    {{ Commas(DetailEventData.Total.Amount) }}
                  </td>
                  <td :style="`text-align: right; color: ${DetailMemberData.Total.member_winlose < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                    {{ Commas(DetailEventData.Total.member_winlose) }}
                  </td>
                  <td :style="`text-align: right; color: ${DetailMemberData.Total.company_winlose < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                    {{ Commas(DetailEventData.Total.company_winlose) }}
                  </td>
                  <td :style="`text-align: right; color: ${DetailMemberData.Total.provider_winlose < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                    {{ Commas(DetailEventData.Total.provider_winlose) }}
                  </td>
                  <td
                    colspan="1"
                  />
                </tr>
              </tfoot> -->
            </table>
          </div>
        </b-overlay>
      </b-modal>

      <b-modal
        ref="bet-all-detail"
        hide-footer
        size="xl"
        :title="`Bet detail: ${DetailMemberData.Row.length > 0 ? DetailMemberData.Row[0].Username : ''}`"
      >
        <b-overlay
          :show="OverMem"
          variant="transparent"
          opacity="0.99"
          blur="5px"
          rounded="sm"
        >
          <template #overlay>
            <div class="text-center">
              <b-icon-controller
                font-scale="3"
                animation="cylon"
              />
              <p id="cancel-label">
                กรุณารอสักครู่...
              </p>
            </div>
          </template>
          <div class="table-responsive">
            <table class="table table-bordered table-hover">
              <thead v-if="Provider[0] !== 'PGPro'">
                <tr>
                  <th
                    class="col-text-padding"
                    style="text-align: center; width: 1%"
                  >
                    No.
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: center; width: 5%"
                  >
                    Provider
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: center; width: 5%"
                  >
                    RefID
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: center; width: 29%"
                  >
                    Information
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: center; width: 10%"
                  >
                    Bet
                  </th>
                  <th style="text-align: right; width: 5%">
                    Amount
                  </th>
                  <th style="text-align: right; width: 5%">
                    Reward
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: right; width: 5%"
                  >
                    Result
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: right; width: 20%"
                  >
                    Timestamp
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: right; width: 20%"
                  >
                    Detail
                  </th>
                </tr>
              </thead>
              <thead v-if="Provider[0] === 'PGPro'">
                <tr>
                  <th
                    class="col-text-padding"
                    style="text-align: center; width: 1%"
                  >
                    No.
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: center; width: 1%"
                  >
                    Provider
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: center; width: 10%"
                  >
                    Bet id
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: center; width: 10%"
                  >
                    Round id
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: center; width: 1%"
                  >
                    Bet amount
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: center; width: 1%"
                  >
                    Bonus
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: center; width: 1%"
                  >
                    W/L
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: center; width: 5%"
                  >
                    Credit before
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: center; width: 5%"
                  >
                    Credit after
                  </th>

                  <th
                    class="col-text-padding"
                    style="text-align: center; width: 10%"
                  >
                    Bet date
                  </th>
                  <th
                    class="col-text-padding"
                    style="text-align: center; width: 1%"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody v-if="Provider[0] === 'PGPro'">
                <tr
                  v-for="(Items, Index) in DetailMemberData.Row"
                  :key="Index"
                >
                  <td>{{ Index + 1 }}</td>
                  <td style="text-align: center">
                    <p style="color: rgb(174, 8, 122)">
                      {{ Items.Provider }}
                    </p>
                  </td>
                  <td style="text-align: center">
                    <p>{{ Items.bet_id }}</p>
                  </td>
                  <td style="text-align: center">
                    <p>{{ Items.round_id }}</p>
                  </td>
                  <td style="text-align: center;color: green">
                    <p>{{ Commas(Items.bet_amount) }}</p>
                  </td>
                  <td style="text-align: center">
                    <p>{{ Commas(Items.bonus_amount) }}</p>
                  </td>
                  <td style="text-align: center">
                    <p :style="{ color: Items.winloss < 0 ? '#dc5455' : (Items.winloss > 0 ? '#3d3dfe' : '#3d3dfe') }">
                      {{ Commas(Items.winloss) }}
                    </p>
                  </td>
                  <td style="text-align: center">
                    <p>{{ Commas(Items.credit_before) }}</p>
                  </td>
                  <td style="text-align: center">
                    <p>{{ Commas(Items.credit_after) }}</p>
                  </td>
                  <td style="text-align: center;color: rgb(153, 92, 31)">
                    <p>Settle time: {{ FormatDate(Items.p_datenow) }}</p>
                  </td>
                  <td style="text-align: right">
                    <b-button
                      variant="primary"
                      size="sm"
                      @click="DetailImgPGPro(Items)"
                    >
                      <i class="fas fa-search" />
                      Detail
                    </b-button>
                  </td>

                </tr>

              </tbody>
              <tbody v-if="Provider[0] !== 'PGPro'">
                <tr
                  v-for="(Items, Index) in DetailMemberData.Row"
                  :key="Index"
                >
                  <td>{{ Index + 1 }}</td>
                  <td style="text-align: center">
                    <p style="color: rgb(174, 8, 122)">
                      {{ Items.Provider }}
                    </p>
                  </td>
                  <td style="text-align: center">
                    <p>{{ Items.ID }}</p>
                  </td>

                  <td
                    v-if="Items.Provider === 'Askmebet'"
                    style="text-align: center"
                  >
                    <p>ProductName: {{ Items.ProductName }}</p>
                    <p>
                      Category: {{ Items.Category }}
                    </p>
                    <p>
                      GameName: {{ Items.GameName }}
                    </p>
                    <p>BetType: {{ Items.BetType }}</p>
                  </td>
                  <td
                    v-if="Items.Provider === 'Lotto'"
                    style="text-align: center"
                  >
                    <p>ProductName: {{ Items.ProductName }}</p>
                    <p>BetType: {{ Items.BetType }}</p>
                  </td>
                  <td
                    v-if="Items.Provider === 'Sbobet'"
                    style="text-align: center"
                  >
                    <p>ProductName: {{ Items.ProductName }}</p>
                    <p>Odds: {{ Items.Odds }}</p>
                    <p v-if="Items.ProductName === 'Football'">
                      League: {{ Items.League }}
                    </p>
                    <p v-if="Items.ProductName === 'Football'">
                      Match: {{ Items.Match }}
                    </p>
                  </td>
                  <td
                    v-if="Items.Provider === 'Awc'"
                    style="text-align: center"
                  >
                    <p>ProductName: {{ Items.ProductName }}</p>
                    <p>
                      Category: {{ Items.Category }}
                    </p>
                    <p>
                      GameName: {{ Items.GameName }}
                    </p>
                    <p>BetType: {{ Items.BetType }}</p>
                  </td>

                  <td style="text-align: right">
                    <p>Bet: {{ Items.Bet ? Items.Bet : '-' }}</p>
                    <p v-if="Items.Provider === 'Askmebet'">
                      Bet status: {{ Items.BetStatus }}
                    </p>
                    <p v-if="Items.Provider === 'Sbobet' && Items.ProductName === 'Football'">
                      HtScore: {{ Items.HtScore }}
                    </p>
                    <p v-if="Items.Provider === 'Sbobet' && Items.ProductName === 'Football'">
                      FtScore: {{ Items.FtScore }}
                    </p>
                  </td>

                  <td style="text-align: right; color: green">
                    <p>{{ Commas(Items.Amount) }}</p>
                  </td>
                  <td :style="`text-align: right; color: ${Items.Reward < 0 ? 'rgb(212, 34, 34)' : 'blue'}`">
                    {{ Commas(Items.Reward) }}
                  </td>

                  <td
                    v-if="Items.Provider === 'Askmebet' || Items.Provider === 'Awc'"
                    :style="`text-align: right; color: ${Items.Result == 'WIN' ? 'rgb(65, 163, 23)' : Items.Result == 'LOSE' ? 'rgb(158, 16, 47)' : '#e8aa35'}`"
                  >
                    {{ Items.Result }}
                  </td>
                  <td
                    v-if="Items.Provider === 'Lotto'"
                    :style="`text-align: right; color: ${Items.Result == 'win' ? 'rgb(65, 163, 23)' : Items.Result == 'lose' ? 'rgb(158, 16, 47)' : '#e8aa35'}`"
                  >
                    {{ Items.Result }}
                  </td>
                  <td
                    v-if="Items.Provider === 'Sbobet'"
                    :style="`text-align: right; color: ${Items.Result == 'won' ? 'rgb(65, 163, 23)' : Items.Result == 'lose' ? 'rgb(158, 16, 47)' : '#e8aa35'}`"
                  >
                    {{ Items.Result }}
                  </td>

                  <td style="text-align: right; color: rgb(153, 92, 31)">
                    <p v-if="Items.Provider === 'Lotto' || Items.Provider === 'Sbobet' || Items.Provider === 'Awc'">
                      Bet time: {{ FormatDate(Items.BetTime) }}
                    </p>
                    <p>Settle time: {{ FormatDate(Items.TimeStamp) }}</p>
                  </td>

                  <td
                    v-if="Items.Provider === 'Askmebet'"
                    :style="`text-align: right; color: ${Items.status_result == 'win' ? 'rgb(65, 163, 23)' : Items.status_result == 'lose' ? 'rgb(158, 16, 47)' : 'rgb(153, 92, 31)'}`"
                  >
                    <b-button
                      variant="primary"
                      size="sm"
                      @click="DetailImg(Items)"
                    >
                      <i class="fas fa-search" />
                      Detail
                    </b-button>
                  </td>
                  <td
                    v-if="Items.Provider === 'Lotto'"
                    style="text-align: right; color: black"
                  >
                    <p>Result Bet: {{ Items.Detail.ResultBet }}</p>
                    <p>Win Rate: {{ Items.Detail.WinRate }}</p>
                    <p>Start Balance: {{ Commas(Items.Detail.StartBalance) }}</p>
                    <p>End Balance: {{ Commas(Items.Detail.EndBalance) }}</p>
                  </td>
                  <td
                    v-if="Items.Provider === 'Sbobet'"
                    :style="`text-align: right; color: ${Items.status_result == 'win' ? 'rgb(65, 163, 23)' : Items.status_result == 'lose' ? 'rgb(158, 16, 47)' : 'rgb(153, 92, 31)'}`"
                  >
                    <b-button
                      variant="primary"
                      size="sm"
                      @click="SboSubbet(Items.Detail)"
                    >
                      <i class="fas fa-search" />
                      SubBet
                    </b-button>
                  </td>
                  <td
                    v-if="Items.Provider === 'Awc'"
                    style="text-align: right; color: black"
                  >
                    <div v-if="ParseJson(Items.Detail).data">
                      <p
                        v-for="(value, key) in ParseJson(Items.Detail).data"
                        :key="key"
                      >
                        {{ key }}: {{ value }}
                      </p>
                    </div>
                    <div v-if="ParseJson(Items.Detail).show">
                      <p
                        v-for="(value, key) in ParseJson(Items.Detail).show"
                        :key="key"
                      >
                        {{ key }}: {{ value }}
                      </p>
                    </div>
                  </td>
                </tr>
              </tbody>
              <!-- <tfoot>
                <tr class="footer">
                  <td style="text-align: right">
                    Total
                  </td>
                  <td
                    colspan="5"
                  />
                  <td style="text-align: right;">
                    {{ Commas(DetailEventData.Total.Tips) }}
                  </td>
                  <td style="text-align: right;">
                    {{ Commas(DetailEventData.Total.ProviderEvent) }}
                  </td>
                  <td style="text-align: right;">
                    {{ Commas(DetailEventData.Total.Amount) }}
                  </td>
                  <td :style="`text-align: right; color: ${DetailMemberData.Total.member_winlose < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                    {{ Commas(DetailEventData.Total.member_winlose) }}
                  </td>
                  <td :style="`text-align: right; color: ${DetailMemberData.Total.company_winlose < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                    {{ Commas(DetailEventData.Total.company_winlose) }}
                  </td>
                  <td :style="`text-align: right; color: ${DetailMemberData.Total.provider_winlose < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                    {{ Commas(DetailEventData.Total.provider_winlose) }}
                  </td>
                  <td
                    colspan="1"
                  />
                </tr>
              </tfoot> -->
            </table>
          </div>
        </b-overlay>
      </b-modal>

      <b-modal
        ref="sbo-subbet-modal"
        hide-footer
        size="lg"
        title="Detail"
      >
        <b-table
          striped
          hover
          responsive
          class="position-relative items-center"
          :items="SboSubbetDetail"
          :fields="FieldSbo"
        >
          <template #cell(index)="data">
            {{ perPage * (currentPage - 1) + (data.index + 1) }}
          </template>
          <template #cell(status)="data">
            <p
              :style="`text-align: right; color: ${data.item.status == 'won' ? 'rgb(65, 163, 23)' : data.item.status == 'lose' ? 'rgb(158, 16, 47)' : 'rgb(153, 92, 31)'}`"
            >
              {{ data.item.status }}
            </p>
          </template>

        </b-table>
      </b-modal>
      <b-modal
        ref="member-select"
        hide-footer
        size="xl"
        title="Member select"
      >
        <div class="table-responsive">
          <table class="table table-bordered table-hover">
            <thead>
              <tr>
                <th colspan="8">
                  <span
                    v-for="(Items, Index) in Station"
                    :key="Index"
                  >
                    <a
                      v-if="Index < Station.length - 1 || Index === 0"
                      @click="SelectMember(Items, 'back')"
                    >
                      <i class="fa fa-user" /> {{ Items }}
                    </a>
                    <a v-else>/ {{ Items }} </a>

                  </span>
                </th>
                <th
                  colspan="3"
                  style="text-align: center"
                >
                  Member
                </th>
                <th
                  colspan="3"
                  style="text-align: center"
                >
                  Company
                </th>
                <th
                  colspan="3"
                  style="text-align: center"
                >
                  Provider
                </th>
              </tr>
              <tr>
                <th
                  class="col-text-padding"
                  style="text-align: center; width: 1%"
                >
                  No.
                </th>
                <th
                  class="col-text-padding"
                  style="text-align: center; width: 10%"
                >
                  Login Name
                </th>
                <th
                  class="col-text-padding"
                  style="text-align: center; width: 5%"
                >
                  Position
                </th>
                <th
                  class="col-text-padding"
                  style="text-align: right; width: 5%"
                >
                  No.Ticket
                </th>
                <th style="text-align: right; width: 5%">
                  Valid turn
                </th>
                <th
                  class="col-text-padding"
                  style="text-align: right; width: 5%"
                >
                  Member W/L <br>(100%)
                </th>
                <th
                  class="col-text-padding"
                  style="text-align: right; width: 8%"
                >
                  Tips
                </th>
                <th
                  class="col-text-padding"
                  style="text-align: right; width: 8%"
                >
                  Provider<br>Event
                </th>
                <th
                  class="col-text-padding"
                  style="text-align: right; width: 5%"
                >
                  W/L
                </th>
                <th style="text-align: right; width: 5%">
                  Comm
                </th>
                <th style="text-align: right; width: 5%">
                  Total
                </th>
                <th
                  class="col-text-padding"
                  style="text-align: right; width: 5%"
                >
                  W/L
                </th>
                <th style="text-align: right; width: 5%">
                  Comm
                </th>
                <th
                  class="col-text-padding"
                  style="text-align: right; width: 5%"
                >
                  Total
                </th>
                <th
                  class="col-text-padding"
                  style="text-align: right; width: 5%"
                >
                  W/L
                </th>
                <th style="text-align: right; width: 5%">
                  Comm
                </th>
                <th
                  class="col-text-padding"
                  style="text-align: right; width: 5%"
                >
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(Items, Index) in SelectAg.slice((currentPageSelect - 1) * perPageSelect, currentPageSelect * perPageSelect)"
                :key="Index"
              >
                <td>{{ perPageSelect * (currentPageSelect - 1) + (Index + 1) }}</td>
                <td
                  v-if="(userData.role == 'master' || userData.role == 'company') && Station.length >= 2"
                  style="text-align: left; color:blue"
                  @click="AllDetailMember(Items)"
                >
                  <p>
                    {{ Items.Username }}
                  </p>
                </td>
                <td
                  v-else-if="userData.role == 'agent'"
                  style="text-align: left; color:blue"
                  @click="AllDetailMember(Items)"
                >
                  <p>
                    {{ Items.Username }}
                  </p>
                </td>
                <td
                  v-else
                  style="text-align: left; color:blue"
                >
                  <p @click="SelectMember(Items.Username, 'next')">
                    {{ Items.Username }}
                  </p>
                </td>

                <td style="text-align: center">
                  {{ Items.Position }}
                </td>
                <td style="text-align: right">
                  {{ Commas(Items.BetCount) }}
                </td>
                <td style="text-align: right; color: black">
                  {{ Commas(Items.ValidStake) }}
                </td>
                <td :style="`text-align: right; color: ${Items.WinLose < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                  {{ Commas(Items.WinLose) }}
                </td>
                <td style="text-align: right; color: black">
                  {{ Commas(Items.Tips) }}
                </td>
                <td style="text-align: right; color: black">
                  {{ Commas(Items.ProviderEvent) }}
                </td>
                <td :style="`text-align: right; color: ${Items.member_winlose < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                  {{ Commas(Items.member_winlose) }}
                </td>
                <td :style="`text-align: right; color: ${Items.member_com < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                  {{ Commas(Items.member_com) }}
                </td>
                <td :style="`text-align: right; color: ${Items.member_total < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                  {{ Commas(Items.member_total) }}
                </td>
                <td
                  :style="`
              background-color: rgb(248, 245, 213);
              text-align: right;
              color: ${Items.company_winlose < 0 ? 'rgb(212, 34, 34)' : 'black'};
            `"
                >
                  {{ Commas(Items.company_winlose) }}
                </td>
                <td
                  :style="`
              background-color: rgb(248, 245, 213);
              text-align: right;
              color: ${Items.company_com < 0 ? 'rgb(212, 34, 34)' : 'black'};
            `"
                >
                  {{ Commas(Items.company_com) }}
                </td>
                <td
                  :style="`
              background-color: rgb(248, 245, 213);
              text-align: right;
              color: ${Items.company_total < 0 ? 'rgb(212, 34, 34)' : 'black'};
            `"
                >
                  {{ Commas(Items.company_total) }}
                </td>
                <td :style="`text-align: right; color: ${Items.provider_winlose < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                  {{ Commas(Items.provider_winlose) }}
                </td>
                <td :style="`text-align: right; color: ${Items.provider_com < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                  {{ Commas(Items.provider_com) }}
                </td>
                <td :style="`text-align: right; color: ${Items.provider_total < 0 ? 'rgb(212, 34, 34)' : 'black'}`">
                  {{ Commas(Items.provider_total) }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr class="footer">
                <td style="text-align: right">
                  Total
                </td>
                <td colspan="2" />
                <td style="text-align: right">
                  {{ SumValue(SelectAg, 'BetCount') }}
                </td>
                <td style="text-align: right;">
                  {{ SumValue(SelectAg, 'ValidStake') }}
                </td>
                <td
                  :style="`text-align: right; color: ${SumValue(SelectAg, 'WinLose') < 0 ? 'rgb(212, 34, 34)' : 'black'}`"
                >
                  {{ SumValue(SelectAg, 'WinLose') }}
                </td>
                <td style="text-align: right;">
                  {{ SumValue(SelectAg, 'Tips') }}
                </td>
                <td style="text-align: right;">
                  {{ SumValue(SelectAg, 'ProviderEvent') }}
                </td>
                <td
                  :style="`text-align: right; color: ${SumValue(SelectAg, 'member_winlose') < 0 ? 'rgb(212, 34, 34)' : 'black'}`"
                >
                  {{ SumValue(SelectAg, 'member_winlose') }}
                </td>
                <td
                  :style="`text-align: right; color: ${SumValue(SelectAg, 'member_com') < 0 ? 'rgb(212, 34, 34)' : 'black'}`"
                >
                  {{ SumValue(SelectAg, 'member_com') }}
                </td>
                <td
                  :style="`text-align: right; color: ${SumValue(SelectAg, 'member_total') < 0 ? 'rgb(212, 34, 34)' : 'black'}`"
                >
                  {{ SumValue(SelectAg, 'member_total') }}
                </td>
                <td
                  :style="`text-align: right; color: ${SumValue(SelectAg, 'company_winlose') < 0 ? 'rgb(212, 34, 34)' : 'black'}`"
                >
                  {{ SumValue(SelectAg, 'company_winlose') }}
                </td>
                <td
                  :style="`text-align: right; color: ${SumValue(SelectAg, 'company_com') < 0 ? 'rgb(212, 34, 34)' : 'black'}`"
                >
                  {{ SumValue(SelectAg, 'company_com') }}
                </td>
                <td
                  :style="`text-align: right; color: ${SumValue(SelectAg, 'company_total') < 0 ? 'rgb(212, 34, 34)' : 'black'}`"
                >
                  {{ SumValue(SelectAg, 'company_total') }}
                </td>
                <td
                  :style="`text-align: right; color: ${SumValue(SelectAg, 'provider_winlose') < 0 ? 'rgb(212, 34, 34)' : 'black'}`"
                >
                  {{ SumValue(SelectAg, 'provider_winlose') }}
                </td>
                <td
                  :style="`text-align: right; color: ${SumValue(SelectAg, 'provider_com') < 0 ? 'rgb(212, 34, 34)' : 'black'}`"
                >
                  {{ SumValue(SelectAg, 'provider_com') }}
                </td>
                <td
                  :style="`text-align: right; color: ${SumValue(SelectAg, 'provider_total') < 0 ? 'rgb(212, 34, 34)' : 'black'}`"
                >
                  {{ SumValue(SelectAg, 'provider_total') }}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>

        <!-- Pagination here -->
        <div class="demo-spacing-0 mx-1 my-1">
          <b-pagination
            v-model="currentPageSelect"
            :total-rows="SelectAg.length"
            :per-page="perPageSelect"
            align="right"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-modal>

    </div>
  </b-overlay>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone'
import {
  BForm, BFormDatepicker, BFormCheckboxGroup, BFormGroup, BFormInput, BPagination,
  BOverlay, BIconController, BModal, BInputGroup, BInputGroupPrepend, BButton,
  BTable, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BForm,
    BFormDatepicker,
    BFormCheckboxGroup,
    BFormGroup,
    BFormInput,
    BPagination,
    vSelect,
    BOverlay,
    BIconController,
    BModal,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    BTable,
    BFormCheckbox,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      ShowOverlay: false,
      currentPage: 1,
      perPage: 50,
      rows: 200,
      currentPageSelect: 1,
      perPageSelect: 50,
      rowsSelect: 200,
      activeBtn: 'btn2',
      login_name: '',
      s_date: '',
      t_date: '',
      currency: '',
      curOption: [
        { value: 'THB', text: 'THB' },
        { value: 'USD', text: 'USD' },
      ],
      product: 'All',
      proOption: [],
      LottoOption: [],
      LottoType: [],
      report_by: false,
      reportOptions: [
        { value: 'product', text: 'Product' },
        // { value: 'category', text: 'Category' },
        // { value: 'account', text: 'Account' },
      ],
      Provider: ['All'],
      ProviderOptions: [
        { value: 'All', text: 'All' },
        { value: 'Askmebet', text: 'Askmebet' },
        { value: 'Awc', text: 'Awc' },
        { value: 'Sbobet', text: 'Sbobet' },
        { value: 'Lotto', text: 'Lotto' },
        { value: 'PGPro', text: 'PG Pro' },
      ],
      GrandTotal: {
        BetCount: 0,
        ValidStake: 0,
        WinLose: 0,
        Tips: 0,
        ProviderEvent: 0,
        member_winlose: 0,
        member_com: 0,
        member_total: 0,
        company_winlose: 0,
        company_com: 0,
        company_total: 0,
        provider_winlose: 0,
        provider_com: 0,
        provider_total: 0,
      },
      GrandRow: [],
      GrandRow_bk: [],
      Grand: {
        Askme: {
          Row: [],
          Total: {},
        },
        Sbobet: {
          Row: [],
          Total: {},
        },
        Awc: {
          Row: [],
          Total: {},
        },
        Lotto: {
          Row: [],
          Total: {},
        },
        PGPro: {
          Row: [],
          Total: {},
        },
      },
      Station: [],
      showProduct: false,
      detailProduct: '',
      DetailMemberData: {
        Row: [],
        Total: {},
      },
      DetailEventData: {
        Row: [],
        Total: {},
      },
      DetailEventLotto: [],
      DetailUrl: '',
      OverMem: false,
      OverEvent: false,
      OverUrl: false,
      BetAllDetail: [],
      SboSubbetDetail: [],
      FieldSbo: [
        { key: 'index', label: 'no.' },
        { key: 'status', label: 'Result' },
        { key: 'league', label: 'league' },
        { key: 'match', label: 'match' },
        { key: 'hdp', label: 'hdp' },
        { key: 'odds', label: 'odds' },
        { key: 'betOption', label: 'Bet' },
        { key: 'htScore', label: 'htScore' },
        { key: 'ftScore', label: 'ftScore' },
        { key: 'kickOffTime', label: 'kickOffTime', formatter: value => moment(value).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss') },
      ],
      SelectAg: [],
    }
  },
  mounted() {
    // this.GetData(this.Provider)
    this.Station = [this.userData.username]
    this.GetProduct()
    this.GetLottoList()
  },
  methods: {
    async showModal(Product) {
      this.$refs['my-modal'].show()
      this.detailProduct = Product
      console.log(Product)
    },
    async showModalSelect() {
      this.$refs['member-select'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    async SelectMember(username, status) {
      if (status === 'next') {
        this.Station.push(username)
      } else {
        this.Station.pop()
      }
      if (username === this.userData.username) {
        // eslint-disable-next-line no-param-reassign
        username = null
      }
      this.GetData(this.Provider, username)
    },
    async GetData(Provider, SubAg) {
      // Askmebet,Awc,Sbobet,Lotto
      if (Provider.length > 0) {
        this.ShowOverlay = true
        if (!this.s_date) {
          this.s_date = moment().tz('Asia/Bangkok').format('YYYY-MM-DD')
        }
        if (!this.t_date) {
          this.t_date = moment().tz('Asia/Bangkok').format('YYYY-MM-DD')
        }
        // console.log(this.s_date, this.t_date)
        try {
          let ProviderStr = Provider.join(',')
          const Index = this.Provider.indexOf('All')
          if (Index > -1) {
            ProviderStr = 'Askmebet, Awc, Sbobet, Lotto, PGPro'
          }
          const params = {
            Provider: ProviderStr,
            StartDate: this.s_date,
            EndDate: this.t_date,
            SubAg,
            AskmeProduct: this.product,
            LottoType: this.LottoType,
            ReportBy: this.report_by,
          }
          const { data: Res } = await this.$http.get('https://reportapi.power100th.com/api/winlose/index', { params })
          // console.log(Res)
          if (Res.status) {
            this.GrandTotal = {
              BetCount: 0,
              ValidStake: 0,
              WinLose: 0,
              Tips: 0,
              ProviderEvent: 0,
              member_winlose: 0,
              member_com: 0,
              member_total: 0,
              company_winlose: 0,
              company_com: 0,
              company_total: 0,
              provider_winlose: 0,
              provider_com: 0,
              provider_total: 0,
            }
            this.GrandRow = []
            this.Grand.Askme.Row = Res.Data.AmbRes.Row
            this.Grand.Askme.Total = Res.Data.AmbRes.Total
            this.Grand.Sbobet.Row = Res.Data.SboRes.Row
            this.Grand.Sbobet.Total = Res.Data.SboRes.Total
            this.Grand.Awc.Row = Res.Data.AwcRes.Row
            this.Grand.Awc.Total = Res.Data.AwcRes.Total
            this.Grand.Lotto.Row = Res.Data.LottoRes.Row
            this.Grand.Lotto.Total = Res.Data.LottoRes.Total
            this.Grand.PGPro.Row = Res.Data.PgproRes.Row
            this.Grand.PGPro.Total = Res.Data.PgproRes.Total

            this.CalRow(Res)
            // if (Res.CalType === 'Member') {
            //   this.CalRowMember(Res)
            // } else {
            //   this.CalRow(Res)
            // }
            if (this.report_by) {
              this.showProduct = true
            } else {
              this.showProduct = false
            }
            this.ShowOverlay = false
            // this.GrandRow = Res.Data.GrandRow
          }
        } catch (error) {
          console.log(error)
          this.ShowOverlay = false
          this.SwalError('เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
        }
      }
    },
    SwalError(message) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: message,
          icon: 'LockIcon',
          variant: 'danger',
        },
      })
    },
    async FilterName(text) {
      if (text) {
        this.GrandRow = this.GrandRow_bk.filter(obj => obj.Username.toLowerCase().includes(text.toLowerCase()))
      } else {
        this.GrandRow = this.GrandRow_bk
      }
    },
    async CalRow(Res) {
      try {
        if (this.Grand.Askme.Row.length > 0) {
          // eslint-disable-next-line no-restricted-syntax
          for (const Items of this.Grand.Askme.Row) {
            const Index = this.GrandRow.findIndex(x => x.Username === Items.Username)
            // const Index = -1
            if (Index > -1) {
              this.GrandRow[Index].BetCount += Number(Items.BetCount)
              this.GrandRow[Index].ValidStake += Number(Items.ValidStake)
              this.GrandRow[Index].WinLose += Number(Items.WinLose)
              this.GrandRow[Index].Tips += Number(Items.Tips)
              this.GrandRow[Index].ProviderEvent += Number(Items.ProviderEvent)
              this.GrandRow[Index].member_winlose += Number(Items.member_winlose)
              this.GrandRow[Index].member_com += Number(Items.member_com)
              this.GrandRow[Index].member_total += Number(Items.member_total)
              this.GrandRow[Index].company_winlose += Number(Items.company_winlose)
              this.GrandRow[Index].company_com += Number(Items.company_com)
              this.GrandRow[Index].company_total += Number(Items.company_total)
              this.GrandRow[Index].provider_winlose += Number(Items.provider_winlose)
              this.GrandRow[Index].provider_com += Number(Items.provider_com)
              this.GrandRow[Index].provider_total += Number(Items.provider_total)
              if (Items.ProductName) {
                this.GrandRow[Index].Product.push({
                  ProductName: Items.ProductName,
                  BetCount: Number(Items.BetCount),
                  ValidStake: Number(Items.ValidStake),
                  WinLose: Number(Items.WinLose),
                  Tips: Number(Items.Tips),
                  ProviderEvent: Number(Items.ProviderEvent),
                  member_winlose: Number(Items.member_winlose),
                  member_com: Number(Items.member_com),
                  member_total: Number(Items.member_total),
                  company_winlose: Number(Items.company_winlose),
                  company_com: Number(Items.company_com),
                  company_total: Number(Items.company_total),
                  provider_winlose: Number(Items.provider_winlose),
                  provider_com: Number(Items.provider_com),
                  provider_total: Number(Items.provider_total),
                })
              }
            } else {
              const Obj = {
                Username: Items.Username,
                Position: 'Member',
                BetCount: Number(Items.BetCount),
                ValidStake: Number(Items.ValidStake),
                WinLose: Number(Items.WinLose),
                Tips: Number(Items.Tips),
                ProviderEvent: Number(Items.ProviderEvent),
                member_winlose: Number(Items.member_winlose),
                member_com: Number(Items.member_com),
                member_total: Number(Items.member_total),
                company_winlose: Number(Items.company_winlose),
                company_com: Number(Items.company_com),
                company_total: Number(Items.company_total),
                provider_winlose: Number(Items.provider_winlose),
                provider_com: Number(Items.provider_com),
                provider_total: Number(Items.provider_total),
                amb_id: Items.amb_id ? Items.amb_id : null,
              }
              if (Items.ProductName) {
                Obj.Product = [
                  {
                    ProductName: Items.ProductName,
                    BetCount: Number(Items.BetCount),
                    ValidStake: Number(Items.ValidStake),
                    WinLose: Number(Items.WinLose),
                    Tips: Number(Items.Tips),
                    ProviderEvent: Number(Items.ProviderEvent),
                    member_winlose: Number(Items.member_winlose),
                    member_com: Number(Items.member_com),
                    member_total: Number(Items.member_total),
                    company_winlose: Number(Items.company_winlose),
                    company_com: Number(Items.company_com),
                    company_total: Number(Items.company_total),
                    provider_winlose: Number(Items.provider_winlose),
                    provider_com: Number(Items.provider_com),
                    provider_total: Number(Items.provider_total),
                  },
                ]
              }
              this.GrandRow.push(Obj)
            }
          }
        }

        if (this.Grand.Sbobet.Row.length > 0) {
          // eslint-disable-next-line no-restricted-syntax
          for (const Items of this.Grand.Sbobet.Row) {
            const Index = this.GrandRow.findIndex(x => x.Username === Items.Username)
            if (Index > -1) {
              this.GrandRow[Index].BetCount += Number(Items.BetCount)
              this.GrandRow[Index].ValidStake += Number(Items.ValidStake)
              this.GrandRow[Index].WinLose += Number(Items.WinLose)
              this.GrandRow[Index].Tips += Number(Items.Tips)
              this.GrandRow[Index].ProviderEvent += Number(Items.ProviderEvent)
              this.GrandRow[Index].member_winlose += Number(Items.member_winlose)
              this.GrandRow[Index].member_com += Number(Items.member_com)
              this.GrandRow[Index].member_total += Number(Items.member_total)
              this.GrandRow[Index].company_winlose += Number(Items.company_winlose)
              this.GrandRow[Index].company_com += Number(Items.company_com)
              this.GrandRow[Index].company_total += Number(Items.company_total)
              this.GrandRow[Index].provider_winlose += Number(Items.provider_winlose)
              this.GrandRow[Index].provider_com += Number(Items.provider_com)
              this.GrandRow[Index].provider_total += Number(Items.provider_total)
              if (Items.ProductName) {
                this.GrandRow[Index].Product.push({
                  ProductName: Items.ProductName,
                  BetCount: Number(Items.BetCount),
                  ValidStake: Number(Items.ValidStake),
                  WinLose: Number(Items.WinLose),
                  Tips: Number(Items.Tips),
                  ProviderEvent: Number(Items.ProviderEvent),
                  member_winlose: Number(Items.member_winlose),
                  member_com: Number(Items.member_com),
                  member_total: Number(Items.member_total),
                  company_winlose: Number(Items.company_winlose),
                  company_com: Number(Items.company_com),
                  company_total: Number(Items.company_total),
                  provider_winlose: Number(Items.provider_winlose),
                  provider_com: Number(Items.provider_com),
                  provider_total: Number(Items.provider_total),
                })
              }
            } else {
              const Obj = {
                Username: Items.Username,
                Position: 'Member',
                BetCount: Number(Items.BetCount),
                ValidStake: Number(Items.ValidStake),
                WinLose: Number(Items.WinLose),
                Tips: Number(Items.Tips),
                ProviderEvent: Number(Items.ProviderEvent),
                member_winlose: Number(Items.member_winlose),
                member_com: Number(Items.member_com),
                member_total: Number(Items.member_total),
                company_winlose: Number(Items.company_winlose),
                company_com: Number(Items.company_com),
                company_total: Number(Items.company_total),
                provider_winlose: Number(Items.provider_winlose),
                provider_com: Number(Items.provider_com),
                provider_total: Number(Items.provider_total),
              }
              if (Items.ProductName) {
                Obj.Product = [
                  {
                    ProductName: Items.ProductName,
                    BetCount: Number(Items.BetCount),
                    ValidStake: Number(Items.ValidStake),
                    WinLose: Number(Items.WinLose),
                    Tips: Number(Items.Tips),
                    ProviderEvent: Number(Items.ProviderEvent),
                    member_winlose: Number(Items.member_winlose),
                    member_com: Number(Items.member_com),
                    member_total: Number(Items.member_total),
                    company_winlose: Number(Items.company_winlose),
                    company_com: Number(Items.company_com),
                    company_total: Number(Items.company_total),
                    provider_winlose: Number(Items.provider_winlose),
                    provider_com: Number(Items.provider_com),
                    provider_total: Number(Items.provider_total),
                  },
                ]
              }
              this.GrandRow.push(Obj)
            }
          }
        }

        if (this.Grand.Awc.Row.length > 0) {
          // eslint-disable-next-line no-restricted-syntax
          for (const Items of this.Grand.Awc.Row) {
            const Index = this.GrandRow.findIndex(x => x.Username === Items.Username)
            if (Index > -1) {
              this.GrandRow[Index].BetCount += Number(Items.BetCount)
              this.GrandRow[Index].ValidStake += Number(Items.ValidStake)
              this.GrandRow[Index].WinLose += Number(Items.WinLose)
              this.GrandRow[Index].Tips += Number(Items.Tips)
              this.GrandRow[Index].ProviderEvent += Number(Items.ProviderEvent)
              this.GrandRow[Index].member_winlose += Number(Items.member_winlose)
              this.GrandRow[Index].member_com += Number(Items.member_com)
              this.GrandRow[Index].member_total += Number(Items.member_total)
              this.GrandRow[Index].company_winlose += Number(Items.company_winlose)
              this.GrandRow[Index].company_com += Number(Items.company_com)
              this.GrandRow[Index].company_total += Number(Items.company_total)
              this.GrandRow[Index].provider_winlose += Number(Items.provider_winlose)
              this.GrandRow[Index].provider_com += Number(Items.provider_com)
              this.GrandRow[Index].provider_total += Number(Items.provider_total)
              if (Items.ProductName) {
                this.GrandRow[Index].Product.push({
                  ProductName: Items.ProductName,
                  BetCount: Number(Items.BetCount),
                  ValidStake: Number(Items.ValidStake),
                  WinLose: Number(Items.WinLose),
                  Tips: Number(Items.Tips),
                  ProviderEvent: Number(Items.ProviderEvent),
                  member_winlose: Number(Items.member_winlose),
                  member_com: Number(Items.member_com),
                  member_total: Number(Items.member_total),
                  company_winlose: Number(Items.company_winlose),
                  company_com: Number(Items.company_com),
                  company_total: Number(Items.company_total),
                  provider_winlose: Number(Items.provider_winlose),
                  provider_com: Number(Items.provider_com),
                  provider_total: Number(Items.provider_total),
                })
              }
            } else {
              const Obj = {
                Username: Items.Username,
                Position: 'Member',
                BetCount: Number(Items.BetCount),
                ValidStake: Number(Items.ValidStake),
                WinLose: Number(Items.WinLose),
                Tips: Number(Items.Tips),
                ProviderEvent: Number(Items.ProviderEvent),
                member_winlose: Number(Items.member_winlose),
                member_com: Number(Items.member_com),
                member_total: Number(Items.member_total),
                company_winlose: Number(Items.company_winlose),
                company_com: Number(Items.company_com),
                company_total: Number(Items.company_total),
                provider_winlose: Number(Items.provider_winlose),
                provider_com: Number(Items.provider_com),
                provider_total: Number(Items.provider_total),
              }
              if (Items.ProductName) {
                Obj.Product = [
                  {
                    ProductName: Items.ProductName,
                    BetCount: Number(Items.BetCount),
                    ValidStake: Number(Items.ValidStake),
                    WinLose: Number(Items.WinLose),
                    Tips: Number(Items.Tips),
                    ProviderEvent: Number(Items.ProviderEvent),
                    member_winlose: Number(Items.member_winlose),
                    member_com: Number(Items.member_com),
                    member_total: Number(Items.member_total),
                    company_winlose: Number(Items.company_winlose),
                    company_com: Number(Items.company_com),
                    company_total: Number(Items.company_total),
                    provider_winlose: Number(Items.provider_winlose),
                    provider_com: Number(Items.provider_com),
                    provider_total: Number(Items.provider_total),
                  },
                ]
              }
              this.GrandRow.push(Obj)
            }
          }
        }

        if (this.Grand.Lotto.Row.length > 0) {
          // eslint-disable-next-line no-restricted-syntax
          for (const Items of this.Grand.Lotto.Row) {
            const Index = this.GrandRow.findIndex(x => x.Username === Items.Username)
            if (Index > -1) {
              this.GrandRow[Index].BetCount += Number(Items.BetCount)
              this.GrandRow[Index].ValidStake += Number(Items.ValidStake)
              this.GrandRow[Index].WinLose += Number(Items.WinLose)
              this.GrandRow[Index].Tips += Number(Items.Tips)
              this.GrandRow[Index].ProviderEvent += Number(Items.ProviderEvent)
              this.GrandRow[Index].member_winlose += Number(Items.member_winlose)
              this.GrandRow[Index].member_com += Number(Items.member_com)
              this.GrandRow[Index].member_total += Number(Items.member_total)
              this.GrandRow[Index].company_winlose += Number(Items.company_winlose)
              this.GrandRow[Index].company_com += Number(Items.company_com)
              this.GrandRow[Index].company_total += Number(Items.company_total)
              this.GrandRow[Index].provider_winlose += Number(Items.provider_winlose)
              this.GrandRow[Index].provider_com += Number(Items.provider_com)
              this.GrandRow[Index].provider_total += Number(Items.provider_total)
              if (Items.ProductName) {
                this.GrandRow[Index].Product.push({
                  ProductName: Items.ProductName,
                  BetCount: Number(Items.BetCount),
                  ValidStake: Number(Items.ValidStake),
                  WinLose: Number(Items.WinLose),
                  Tips: Number(Items.Tips),
                  ProviderEvent: Number(Items.ProviderEvent),
                  member_winlose: Number(Items.member_winlose),
                  member_com: Number(Items.member_com),
                  member_total: Number(Items.member_total),
                  company_winlose: Number(Items.company_winlose),
                  company_com: Number(Items.company_com),
                  company_total: Number(Items.company_total),
                  provider_winlose: Number(Items.provider_winlose),
                  provider_com: Number(Items.provider_com),
                  provider_total: Number(Items.provider_total),
                })
              }
              this.GrandRow[Index].LottoBetList = Items.BetList
            } else {
              const Obj = {
                Username: Items.Username,
                Position: 'Member',
                BetCount: Number(Items.BetCount),
                ValidStake: Number(Items.ValidStake),
                WinLose: Number(Items.WinLose),
                Tips: Number(Items.Tips),
                ProviderEvent: Number(Items.ProviderEvent),
                member_winlose: Number(Items.member_winlose),
                member_com: Number(Items.member_com),
                member_total: Number(Items.member_total),
                company_winlose: Number(Items.company_winlose),
                company_com: Number(Items.company_com),
                company_total: Number(Items.company_total),
                provider_winlose: Number(Items.provider_winlose),
                provider_com: Number(Items.provider_com),
                provider_total: Number(Items.provider_total),
              }
              if (Items.ProductName) {
                Obj.Product = [
                  {
                    ProductName: Items.ProductName,
                    BetCount: Number(Items.BetCount),
                    ValidStake: Number(Items.ValidStake),
                    WinLose: Number(Items.WinLose),
                    Tips: Number(Items.Tips),
                    ProviderEvent: Number(Items.ProviderEvent),
                    member_winlose: Number(Items.member_winlose),
                    member_com: Number(Items.member_com),
                    member_total: Number(Items.member_total),
                    company_winlose: Number(Items.company_winlose),
                    company_com: Number(Items.company_com),
                    company_total: Number(Items.company_total),
                    provider_winlose: Number(Items.provider_winlose),
                    provider_com: Number(Items.provider_com),
                    provider_total: Number(Items.provider_total),
                  },
                ]
              }
              Obj.LottoBetList = Items.BetList

              this.GrandRow.push(Obj)
            }
          }
        }

        if (this.Grand.PGPro.Row.length > 0) {
          // eslint-disable-next-line no-restricted-syntax
          for (const Items of this.Grand.PGPro.Row) {
            const Index = this.GrandRow.findIndex(x => x.Username === Items.Username)
            // const Index = -1
            if (Index > -1) {
              this.GrandRow[Index].BetCount += Number(Items.BetCount)
              this.GrandRow[Index].ValidStake += Number(Items.ValidStake)
              this.GrandRow[Index].WinLose += Number(Items.WinLose)
              this.GrandRow[Index].Tips += Number(Items.Tips)
              this.GrandRow[Index].ProviderEvent += Number(Items.ProviderEvent)
              this.GrandRow[Index].member_winlose += Number(Items.member_winlose)
              this.GrandRow[Index].member_com += Number(Items.member_com)
              this.GrandRow[Index].member_total += Number(Items.member_total)
              this.GrandRow[Index].company_winlose += Number(Items.company_winlose)
              this.GrandRow[Index].company_com += Number(Items.company_com)
              this.GrandRow[Index].company_total += Number(Items.company_total)
              this.GrandRow[Index].provider_winlose += Number(Items.provider_winlose)
              this.GrandRow[Index].provider_com += Number(Items.provider_com)
              this.GrandRow[Index].provider_total += Number(Items.provider_total)
              if (Items.ProductName) {
                this.GrandRow[Index].Product.push({
                  ProductName: Items.ProductName,
                  BetCount: Number(Items.BetCount),
                  ValidStake: Number(Items.ValidStake),
                  WinLose: Number(Items.WinLose),
                  Tips: Number(Items.Tips),
                  ProviderEvent: Number(Items.ProviderEvent),
                  member_winlose: Number(Items.member_winlose),
                  member_com: Number(Items.member_com),
                  member_total: Number(Items.member_total),
                  company_winlose: Number(Items.company_winlose),
                  company_com: Number(Items.company_com),
                  company_total: Number(Items.company_total),
                  provider_winlose: Number(Items.provider_winlose),
                  provider_com: Number(Items.provider_com),
                  provider_total: Number(Items.provider_total),
                  r_id: Number(Items.r_id),
                  m_id: Number(Items.m_id),
                })
              }
            } else {
              const Obj = {
                Username: Items.Username,
                Position: 'Member',
                BetCount: Number(Items.BetCount),
                ValidStake: Number(Items.ValidStake),
                WinLose: Number(Items.WinLose),
                Tips: Number(Items.Tips),
                ProviderEvent: Number(Items.ProviderEvent),
                member_winlose: Number(Items.member_winlose),
                member_com: Number(Items.member_com),
                member_total: Number(Items.member_total),
                company_winlose: Number(Items.company_winlose),
                company_com: Number(Items.company_com),
                company_total: Number(Items.company_total),
                provider_winlose: Number(Items.provider_winlose),
                provider_com: Number(Items.provider_com),
                provider_total: Number(Items.provider_total),
                r_id: Number(Items.r_id),
                m_id: Number(Items.m_id),
              }
              if (Items.ProductName) {
                Obj.Product = [
                  {
                    ProductName: Items.ProductName,
                    BetCount: Number(Items.BetCount),
                    ValidStake: Number(Items.ValidStake),
                    WinLose: Number(Items.WinLose),
                    Tips: Number(Items.Tips),
                    ProviderEvent: Number(Items.ProviderEvent),
                    member_winlose: Number(Items.member_winlose),
                    member_com: Number(Items.member_com),
                    member_total: Number(Items.member_total),
                    company_winlose: Number(Items.company_winlose),
                    company_com: Number(Items.company_com),
                    company_total: Number(Items.company_total),
                    provider_winlose: Number(Items.provider_winlose),
                    provider_com: Number(Items.provider_com),
                    provider_total: Number(Items.provider_total),
                    r_id: Number(Items.r_id),
                    m_id: Number(Items.m_id),
                  },
                ]
              }
              this.GrandRow.push(Obj)
            }
          }
        }

        this.GrandRow_bk = [...this.GrandRow]
        if (this.login_name) {
          this.GrandRow = this.GrandRow_bk.filter(obj => obj.Username.toLowerCase().includes(this.login_name.toLowerCase()))
          // eslint-disable-next-line
          for (const Index in this.GrandRow) {
            const items = this.GrandRow[Index]
            this.GrandTotal.BetCount += Number(items.BetCount)
            this.GrandTotal.ValidStake += Number(items.ValidStake)
            this.GrandTotal.WinLose += Number(items.WinLose)
            this.GrandTotal.Tips += Number(items.Tips)
            this.GrandTotal.ProviderEvent += Number(items.ProviderEvent)
            this.GrandTotal.member_winlose += Number(items.member_winlose)
            this.GrandTotal.member_com += Number(items.member_com)
            this.GrandTotal.member_total += Number(items.member_total)
            this.GrandTotal.company_winlose += Number(items.company_winlose)
            this.GrandTotal.company_com += Number(items.company_com)
            this.GrandTotal.company_total += Number(items.company_total)
            this.GrandTotal.provider_winlose += Number(items.provider_winlose)
            this.GrandTotal.provider_com += Number(items.provider_com)
            this.GrandTotal.provider_total += Number(items.provider_total)
          }
        } else {
          // eslint-disable-next-line
          for (const Index in Res.Data) {
            this.GrandTotal.BetCount += Number(Res.Data[Index].Total.BetCount)
            this.GrandTotal.ValidStake += Number(Res.Data[Index].Total.ValidStake)
            this.GrandTotal.WinLose += Number(Res.Data[Index].Total.WinLose)
            this.GrandTotal.Tips += Number(Res.Data[Index].Total.Tips)
            this.GrandTotal.ProviderEvent += Number(Res.Data[Index].Total.ProviderEvent)
            this.GrandTotal.member_winlose += Number(Res.Data[Index].Total.member_winlose)
            this.GrandTotal.member_com += Number(Res.Data[Index].Total.member_com)
            this.GrandTotal.member_total += Number(Res.Data[Index].Total.member_total)
            this.GrandTotal.company_winlose += Number(Res.Data[Index].Total.company_winlose)
            this.GrandTotal.company_com += Number(Res.Data[Index].Total.company_com)
            this.GrandTotal.company_total += Number(Res.Data[Index].Total.company_total)
            this.GrandTotal.provider_winlose += Number(Res.Data[Index].Total.provider_winlose)
            this.GrandTotal.provider_com += Number(Res.Data[Index].Total.provider_com)
            this.GrandTotal.provider_total += Number(Res.Data[Index].Total.provider_total)
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    onSubmit() {
      // console.log('Testing')
      this.GetData(this.Provider)
      this.Station = [this.userData.username]
    },
    async GetProduct() {
      try {
        const { data: Res } = await this.$http.get('https://reportapi.power100th.com/api/product/list')
        this.proOption = Res
        this.proOption.unshift({ productId: 'All', productName: 'All' })
      } catch (error) {
        console.log(error)
      }
    },
    async GetLottoList() {
      try {
        const { data: Res } = await this.$http.get('https://reportapi.power100th.com/api/lotto/list')
        this.LottoOption = Res
        // this.LottoOption.unshift({ LottoTypeHead: 'All', LottoHeadName: 'All' })
      } catch (error) {
        console.log(error)
      }
    },
    async AllDetailMember(Items) {
      this.DetailMemberData = {
        Row: [],
        Total: {},
      }
      try {
        this.OverMem = true

        this.$refs['bet-all-detail'].show()
        if (this.Provider.includes('Askmebet') || this.Provider.includes('All')) {
          const AmbDetail = await this.EventAllAmb(Items)
          if (AmbDetail.success) {
            // eslint-disable-next-line no-restricted-syntax
            for (const AmbBet of AmbDetail.data) {
              const Obj = {
                Username: Items.Username,
                TxnID: AmbBet.txnId,
                Provider: 'Askmebet',
                ProductName: AmbBet.productId,
                Category: AmbBet.category,
                BetType: AmbBet.betType === 'UNKNOWN' ? '-' : AmbBet.betType,
                GameName: AmbBet.gameName === 'UNKNOWN' ? '-' : AmbBet.gameName,
                Bet: AmbBet.playInfo === 'UNKNOWN' ? '-' : AmbBet.playInfo,
                BetStatus: AmbBet.betStatus === 'UNKNOWN' ? '-' : AmbBet.betStatus,
                Amount: AmbBet.realBets,
                Reward: AmbBet.total.member,
                Result: AmbBet.payoutStatus,
                TimeStamp: AmbBet.dateTime,
                ID: AmbBet.id,
                amb_id: Items.amb_id,
                Detail: AmbBet.betDetail,
              }
              this.DetailMemberData.Row.push(Obj)
            }
          }
        }

        if ((this.Provider.includes('Lotto') || this.Provider.includes('All')) && Items.LottoBetList) {
          // eslint-disable-next-line no-restricted-syntax
          for (const LottoBet of Items.LottoBetList) {
            const Obj = {
              Username: Items.Username,
              TxnID: LottoBet.list_id,
              Provider: 'Lotto',
              ProductName: LottoBet.LottoHeadName,
              Category: '',
              BetType: LottoBet.name_type,
              GameName: '',
              Bet: LottoBet.bet,
              BetStatus: LottoBet.betStatus,
              Amount: LottoBet.amount,
              Reward: LottoBet.reward,
              Result: LottoBet.status_result,
              TimeStamp: LottoBet.updated_at,
              BetTime: LottoBet.created_at,
              // eslint-disable-next-line no-underscore-dangle
              ID: LottoBet._id,
              Detail: {
                ResultBet: LottoBet.result_bet,
                WinRate: LottoBet.win_rate,
                StartBalance: LottoBet.startbalance,
                EndBalance: LottoBet.endbalance,
              },
            }
            this.DetailMemberData.Row.push(Obj)
          }
        }

        if (this.Provider.includes('Sbobet') || this.Provider.includes('All')) {
          const SboDetail = await this.EventSbo(Items)
          if (SboDetail.success) {
            // eslint-disable-next-line no-restricted-syntax
            for (const SboBet of SboDetail.data) {
              const Obj = {
                Username: Items.Username,
                Provider: 'Sbobet',
                ProductName: SboBet.sportsType,
                Odds: SboBet.odds,
                Match: SboBet.sportsType === 'Football' ? SboBet.subBet[0].match : '',
                Bet: SboBet.sportsType === 'Football' ? SboBet.subBet[0].betOption : '-',
                League: SboBet.sportsType === 'Football' ? SboBet.subBet[0].league : '',
                HtScore: SboBet.sportsType === 'Football' ? SboBet.subBet[0].htScore : '',
                FtScore: SboBet.sportsType === 'Football' ? SboBet.subBet[0].ftScore : '',
                Amount: SboBet.stake,
                Reward: SboBet.winLost,
                Result: SboBet.status,
                TimeStamp: SboBet.settleTime,
                BetTime: SboBet.orderTime,
                ID: SboBet.refNo,
                Detail: SboBet.subBet,
              }
              this.DetailMemberData.Row.push(Obj)
            }
          }
        }
        if (this.Provider.includes('Awc') || this.Provider.includes('All')) {
          const AwcDetail = await this.EventAwc(Items)
          if (AwcDetail.success) {
            // eslint-disable-next-line no-restricted-syntax
            for (const AwcBet of AwcDetail.data) {
              const Obj = {
                Username: Items.Username,
                Provider: 'Awc',
                ProductName: AwcBet.platform,
                Category: AwcBet.gameType,
                BetType: AwcBet.betType,
                GameName: AwcBet.gameName,
                Amount: AwcBet.totalBetAmt,
                Reward: AwcBet.winLoss5,
                Result: AwcBet.status,
                TimeStamp: moment(AwcBet.settleTime, 'DD-MM-YYYY HH:mm:ss').toDate(),
                BetTime: moment(AwcBet.betTime, 'DD-MM-YYYY HH:mm:ss').toDate(),
                ID: AwcBet.roundId,
                Detail: AwcBet.gameInfo,
              }
              this.DetailMemberData.Row.push(Obj)
            }
          }
        }
        if (this.Provider.includes('PGPro') || this.Provider.includes('All')) {
          const PGPro = await this.EventPGPro(Items)
          console.log(PGPro)

          if (PGPro.success) {
            // eslint-disable-next-line no-restricted-syntax
            for (const PGPro of PGPro.data) {
              const Obj = {
                Username: PGPro.m_user,
                Provider: 'PG Pro',
                p_datenow: moment(PGPro.p_datenow).toDate(),
                bet_id: PGPro.bet_id,
                round_id: PGPro.round_id,
                bet_amount: PGPro.bet_amount,
                bonus_amount: PGPro.bonus_amount,
                credit_before: PGPro.credit_before,
                credit_after: PGPro.credit_after,
                winloss: PGPro.winloss,
              }
              this.DetailMemberData.Row.push(Obj)
            }
          }
        }

        // console.log('Row')
        // console.log(this.DetailMemberData)
        this.DetailMemberData.Row = await this.sortByTimeStamp(this.DetailMemberData.Row)
        this.OverMem = false
        // this.$refs['detail-modal'].hide()
      } catch (e) {
        console.log(e)
        this.OverMem = false
        this.$refs['detail-modal'].hide()
      }
    },
    sortByTimeStamp(Items) {
      if (Items.length === 0) return []
      return Items.sort((a, b) => moment(a.TimeStamp).toDate() - moment(b.TimeStamp).toDate())
    },
    ParseJson(Items) {
      if (Items) {
        const parsedOuter = JSON.parse(Items)
        const parsedData = JSON.parse(parsedOuter.data)
        const parsedShow = JSON.parse(parsedOuter.show)
        return { data: parsedData, show: parsedShow }
      }
      return { data: {}, show: {} }
    },
    async EventPGPro(Items) {
      console.log(Items)

      try {
        const params = {
          StartDate: this.s_date,
          EndDate: this.t_date,
          AskmeProduct: this.product,
          Ag: this.Station[this.Station.length - 1],
          Role: this.userData.role,
          m_id: Items.m_id,
        }
        const { data: Res } = await this.$http.get('https://reportapi.power100th.com/api/pgpro/detail/member', { params })
        if (Res.message === 'Success') {
          return { success: true, data: Res.data }
        }
      } catch (e) {
        console.log(e)
        return { success: false, data: [] }
      }
      return { success: false, data: [] }
    },
    async EventAllAmb(Items) {
      try {
        const params = {
          ID: Items.amb_id,
          StartDate: this.s_date,
          EndDate: this.t_date,
          AskmeProduct: this.product,
          Ag: this.Station[this.Station.length - 1],
          Role: this.userData.role,
        }
        const { data: Res } = await this.$http.get('https://reportapi.power100th.com/api/amb/detail/memberall', { params })
        if (Res.status) {
          return { success: true, data: Res.Data }
        }
      } catch (e) {
        console.log(e)
        return { success: false, data: [] }
      }
      return { success: false, data: [] }
    },
    async EventSbo(Items) {
      try {
        const params = {
          StartDate: this.s_date,
          EndDate: this.t_date,
          username: Items.Username,
          Ag: this.Station[this.Station.length - 1],
        }
        const { data: Res } = await this.$http.get('https://reportapi.power100th.com/api/sbo/detail/member', { params })
        if (Res.success && Res.Data) {
          return { success: true, data: Res.Data }
        }
      } catch (e) {
        console.log(e)
        return { success: false, data: [] }
      }
      return { success: false, data: [] }
    },
    async EventAwc(Items) {
      try {
        const params = {
          StartDate: this.s_date,
          EndDate: this.t_date,
          username: Items.Username,
          Ag: this.Station[this.Station.length - 1],
        }
        const { data: Res } = await this.$http.get('https://reportapi.power100th.com/api/awc/detail/member', { params })
        if (Res.success && Res.Row) {
          return { success: true, data: Res.Row }
        }
      } catch (e) {
        console.log(e)
        return { success: false, data: [] }
      }
      return { success: false, data: [] }
    },
    async DetailImgPGPro(Items) {
      try {
        const width = 416
        const height = 737
        const left = (window.innerWidth - width) / 2
        const top = (window.innerHeight - height) / 2

        const options = `
          width=${width},
          height=${height},
          left=${left},
          top=${top},
          resizable=yes,
          scrollbars=yes
        `
        this.OverUrl = true
        // this.$refs['url-modal'].show()
        console.log(Items)
        const obj = {
          ID: Items.bet_id,
          user: this.Station[this.Station.length - 1],
          Role: this.userData.role,
        }
        const { data: Res } = await this.$http.post('https://reportapi.power100th.com/api/pgpro/detail_img', obj)
        if (Res.message === 'Success') {
          this.DetailUrl = Res.data.url
          this.OverUrl = false
        }

        window.open(this.DetailUrl, '_blank', options)
      } catch (e) {
        console.log(e)
        // this.$refs['url-modal'].hide()
        this.OverUrl = false
      }
    },
    async DetailImg(Items) {
      try {
        const width = 416
        const height = 737
        const left = (window.innerWidth - width) / 2
        const top = (window.innerHeight - height) / 2

        const options = `
          width=${width},
          height=${height},
          left=${left},
          top=${top},
          resizable=yes,
          scrollbars=yes
        `
        this.OverUrl = true
        // this.$refs['url-modal'].show()
        console.log(Items)
        const obj = {
          ID: Items.ID,
          product: Items.ProductName,
          username: Items.amb_id,
          betDetail: Items.Detail,
        }
        const { data: Res } = await this.$http.post('https://reportapi.power100th.com/api/amb/detail/url', obj)
        if (Res.success) {
          this.DetailUrl = Res.msg.url
          this.OverUrl = false
        }

        window.open(this.DetailUrl, '_blank', options)
      } catch (e) {
        console.log(e)
        // this.$refs['url-modal'].hide()
        this.OverUrl = false
      }
    },
    async SboSubbet(Items) {
      try {
        this.$refs['sbo-subbet-modal'].show()
        this.SboSubbetDetail = Items
      } catch (e) {
        console.log(e)
        this.$refs['sbo-subbet-modal'].hide()
      }
    },
    async SelectDate(Key) {
      this.activeBtn = Key
      if (!this.s_date) {
        this.s_date = moment().tz('Asia/Bangkok').format('YYYY-MM-DD')
      }
      if (!this.t_date) {
        this.t_date = moment().tz('Asia/Bangkok').format('YYYY-MM-DD')
      }
      if (Key === 'Provious') {
        this.s_date = moment(this.s_date).tz('Asia/Bangkok').subtract(1, 'days').format('YYYY-MM-DD')
        this.t_date = moment(this.t_date).tz('Asia/Bangkok').subtract(1, 'days').format('YYYY-MM-DD')
      } else if (Key === 'Next') {
        this.s_date = moment(this.s_date).tz('Asia/Bangkok').add(1, 'days').format('YYYY-MM-DD')
        this.t_date = moment(this.t_date).tz('Asia/Bangkok').add(1, 'days').format('YYYY-MM-DD')
      } else if (Key === 'Today') {
        this.s_date = moment().tz('Asia/Bangkok').format('YYYY-MM-DD')
        this.t_date = moment().tz('Asia/Bangkok').format('YYYY-MM-DD')
      } else if (Key === 'Yesterday') {
        this.s_date = moment().tz('Asia/Bangkok').subtract(1, 'days').format('YYYY-MM-DD')
        this.t_date = moment().tz('Asia/Bangkok').subtract(1, 'days').format('YYYY-MM-DD')
      } else if (Key === 'ThisWeek') {
        this.s_date = moment().tz('Asia/Bangkok').startOf('week').format('YYYY-MM-DD')
        this.t_date = moment().tz('Asia/Bangkok').endOf('week').format('YYYY-MM-DD')
      } else if (Key === 'LastWeek') {
        this.s_date = moment().tz('Asia/Bangkok').subtract(1, 'weeks').startOf('week')
          .format('YYYY-MM-DD')
        this.t_date = moment().tz('Asia/Bangkok').subtract(1, 'weeks').endOf('week')
          .format('YYYY-MM-DD')
      } else if (Key === 'ThisMonth') {
        this.s_date = moment().tz('Asia/Bangkok').startOf('month').format('YYYY-MM-DD')
        this.t_date = moment().tz('Asia/Bangkok').endOf('month').format('YYYY-MM-DD')
      } else if (Key === 'LastMonth') {
        this.s_date = moment().tz('Asia/Bangkok').subtract(1, 'months').startOf('month')
          .format('YYYY-MM-DD')
        this.t_date = moment().tz('Asia/Bangkok').subtract(1, 'months').endOf('month')
          .format('YYYY-MM-DD')
      }
      this.GetData(this.Provider)
      this.Station = [this.userData.username]
    },
    ChangeProvider() {
      const Last = this.Provider[this.Provider.length - 1]
      if (Last === 'All') {
        this.Provider = ['All']
      } else {
        const Index = this.Provider.indexOf('All')
        if (Index > -1) {
          this.Provider.splice(Index, 1)
        }
      }
    },
    SumValue(Items, Key) {
      if (Items && Items.length > 0) {
        return this.Commas(Items.reduce((a, b) => a + Number(b[Key]), 0))
      }
      return 0
    },
    Commas(x) {
      if (x) {
        // console.log(x)
        // console.log(Math.round(x) !== x)
        if (Math.round(x) !== x) {
          const Num = Number(x)
          const y = Num.toFixed(2)
          return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return 0
    },
    FormatDate(date) {
      return moment(date).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm:ss')
    },
    async DetailMember(Items) {
      this.DetailMemberData = {
        Row: [],
        Total: {},
      }
      try {
        this.OverMem = true
        this.$refs['detail-modal'].show()
        if (this.Provider.includes('Askmebet') || this.Provider.includes('All')) {
          const AmbDetail = await this.DetailAmb(Items)
          if (AmbDetail.success) {
            this.DetailMemberData.Row = this.DetailMemberData.Row.concat(AmbDetail.data.Row)
          }
        }
        if (this.Provider.includes('Lotto') || this.Provider.includes('All')) {
          const LottoDetail = Items.Product.filter(obj => obj.ProductName === 'Lotto')
          if (LottoDetail.length > 0) {
            const LottoJson = LottoDetail[0]
            LottoJson.Username = Items.Username
            LottoJson.StartTime = `${this.s_date} 00:00:00`
            LottoJson.EndTime = `${this.t_date} 23:59:59`
            LottoJson.LottoBetList = Items.LottoBetList
            this.DetailMemberData.Row.push(LottoJson)
          }
        }
        if (this.DetailMemberData.Row && this.DetailMemberData.Row.length > 0) {
          this.DetailMemberData.Total.BetCount = this.DetailMemberData.Row.reduce((a, b) => a + Number(b.BetCount), 0)
          this.DetailMemberData.Total.ProviderEvent = this.DetailMemberData.Row.reduce((a, b) => a + Number(b.ProviderEvent), 0)
          this.DetailMemberData.Total.Tips = this.DetailMemberData.Row.reduce((a, b) => a + Number(b.Tips), 0)
          this.DetailMemberData.Total.ValidStake = this.DetailMemberData.Row.reduce((a, b) => a + Number(b.ValidStake), 0)
          this.DetailMemberData.Total.WinLose = this.DetailMemberData.Row.reduce((a, b) => a + Number(b.WinLose), 0)
          this.DetailMemberData.Total.member_winlose = this.DetailMemberData.Row.reduce((a, b) => a + Number(b.member_winlose), 0)
          this.DetailMemberData.Total.member_com = this.DetailMemberData.Row.reduce((a, b) => a + Number(b.member_com), 0)
          this.DetailMemberData.Total.member_total = this.DetailMemberData.Row.reduce((a, b) => a + Number(b.member_total), 0)
          this.DetailMemberData.Total.company_winlose = this.DetailMemberData.Row.reduce((a, b) => a + Number(b.company_winlose), 0)
          this.DetailMemberData.Total.company_com = this.DetailMemberData.Row.reduce((a, b) => a + Number(b.company_com), 0)
          this.DetailMemberData.Total.company_total = this.DetailMemberData.Row.reduce((a, b) => a + Number(b.company_total), 0)
          this.DetailMemberData.Total.provider_winlose = this.DetailMemberData.Row.reduce((a, b) => a + Number(b.provider_winlose), 0)
          this.DetailMemberData.Total.provider_com = this.DetailMemberData.Row.reduce((a, b) => a + Number(b.provider_com), 0)
          this.DetailMemberData.Total.provider_total = this.DetailMemberData.Row.reduce((a, b) => a + Number(b.provider_total), 0)
        }
        this.OverMem = false
        // this.$refs['detail-modal'].hide()
      } catch (e) {
        console.log(e)
        this.OverMem = false
        this.$refs['detail-modal'].hide()
      }
    },
    async DetailAmb(Items) {
      try {
        const params = {
          ID: Items.amb_id,
          StartDate: this.s_date,
          EndDate: this.t_date,
          AskmeProduct: this.product,
          Ag: this.Station[this.Station.length - 1],
          Role: this.userData.role,
        }
        const { data: Res } = await this.$http.get('https://reportapi.power100th.com/api/amb/detail/member', { params })
        if (Res.status) {
          return { success: true, data: Res.Data }
        }
      } catch (e) {
        console.log(e)
        return { success: false, data: [] }
      }
      return { success: false, data: [] }
    },
    async DetailEvent(Items) {
      console.log(Items)
      this.OverEvent = true
      if (Items.ProductName === 'Lotto') {
        this.$refs['event-lotto'].show()
        this.DetailEventLotto = Items.LottoBetList
      } else {
        const EventAmb = await this.EventAmb(Items)
        if (EventAmb.success) {
          this.DetailEventData = EventAmb.data
        }
      }
      console.log(this.DetailEventLotto)
      this.OverEvent = false
    },
    async EventAmb(Items) {
      try {
        this.$refs['event-amb'].show()
        const params = {
          ID: Items.amb_id,
          StartDate: Items.StartTime,
          EndDate: Items.EndTime,
          AskmeProduct: this.product,
          Ag: this.Station[this.Station.length - 1],
          Ref: Items.ref,
          Role: this.userData.role,
        }
        const { data: Res } = await this.$http.get('https://reportapi.power100th.com/api/amb/detail/event', { params })
        if (Res.status) {
          return { success: true, data: Res.Data }
        }
      } catch (e) {
        console.log(e)
        this.$refs['event-amb'].hide()
        return { success: false, data: [] }
      }
      return { success: false, data: [] }
    },
  },
}
</script>

<style></style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
